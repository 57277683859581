// parameter input
export const CUSTOM_API_CALL_KEY = "__CUSTOM_API_CALL__";
export const CUSTOM_API_CALL_NAME = "Custom API Call";

// workflows
export const PLACEHOLDER_EMPTY_WORKFLOW_ID = "__EMPTY__";
export const NEW_WORKFLOW_ID = "new";
export const DEFAULT_NODETYPE_VERSION = 1;
export const DEFAULT_NEW_WORKFLOW_NAME = "My workflow";
export const MIN_WORKFLOW_NAME_LENGTH = 1;
export const MAX_WORKFLOW_NAME_LENGTH = 128;
export const DUPLICATE_POSTFFIX = " copy";
export const NODE_OUTPUT_DEFAULT_KEY = "_NODE_OUTPUT_DEFAULT_KEY_";
export const DEFAULT_WORKFLOW_PAGE_SIZE = 10;

// tags
export const MAX_TAG_NAME_LENGTH = 24;

export const CREDENTIAL_ONLY_NODE_PREFIX = "n8n-creds-base";

// categories
export const CORE_NODES_CATEGORY = "Core Nodes";
export const HUMAN_IN_THE_LOOP_CATEGORY = "HITL";
export const CUSTOM_NODES_CATEGORY = "Custom Nodes";

export const DEFAULT_SUBCATEGORY = "*";

export const AI_OTHERS_NODE_CREATOR_VIEW = "AI Other";
export const AI_NODE_CREATOR_VIEW = "AI";
export const REGULAR_NODE_CREATOR_VIEW = "Regular";
export const TRIGGER_NODE_CREATOR_VIEW = "Trigger";

export const OTHER_TRIGGER_NODES_SUBCATEGORY = "Other Trigger Nodes";
export const TRANSFORM_DATA_SUBCATEGORY = "Data Transformation";
export const FILES_SUBCATEGORY = "Files";
export const FLOWS_CONTROL_SUBCATEGORY = "Flow";
export const AI_SUBCATEGORY = "AI";
export const HELPERS_SUBCATEGORY = "Helpers";
export const HITL_SUBCATEGORY = "Human in the Loop";

export const AI_CATEGORY_AGENTS = "Agents";
export const AI_CATEGORY_CHAINS = "Chains";
export const AI_CATEGORY_LANGUAGE_MODELS = "Language Models";
export const AI_CATEGORY_MEMORY = "Memory";
export const AI_CATEGORY_OUTPUTPARSER = "Output Parsers";
export const AI_CATEGORY_TOOLS = "Tools";
export const AI_CATEGORY_VECTOR_STORES = "Vector Stores";
export const AI_CATEGORY_RETRIEVERS = "Retrievers";
export const AI_CATEGORY_EMBEDDING = "Embeddings";
export const AI_CATEGORY_DOCUMENT_LOADERS = "Document Loaders";
export const AI_CATEGORY_TEXT_SPLITTERS = "Text Splitters";
export const AI_CATEGORY_OTHER_TOOLS = "Other Tools";
export const AI_CATEGORY_ROOT_NODES = "Root Nodes";
export const AI_UNCATEGORIZED_CATEGORY = "Miscellaneous";
export const AI_CODE_TOOL_LANGCHAIN_NODE_TYPE = "neurode.toolCode";
export const AI_WORKFLOW_TOOL_LANGCHAIN_NODE_TYPE = "neurode.toolWorkflow";

// node types
export const BAMBOO_HR_NODE_TYPE = "neurode.bambooHr";
export const CALENDLY_TRIGGER_NODE_TYPE = "neurode.calendlyTrigger";
export const CODE_NODE_TYPE = "neurode.code";
export const AI_CODE_NODE_TYPE = "neurode/langchain.code";
export const CRON_NODE_TYPE = "neurode.cron";
export const CLEARBIT_NODE_TYPE = "neurode.clearbit";
export const FILTER_NODE_TYPE = "neurode.filter";
export const FUNCTION_NODE_TYPE = "neurode.function";
export const GITHUB_TRIGGER_NODE_TYPE = "neurode.githubTrigger";
export const GIT_NODE_TYPE = "neurode.git";
export const GOOGLE_GMAIL_NODE_TYPE = "neurode.gmail";
export const GOOGLE_SHEETS_NODE_TYPE = "neurode.googleSheets";
export const ERROR_TRIGGER_NODE_TYPE = "neurode.errorTrigger";
export const ELASTIC_SECURITY_NODE_TYPE = "neurode.elasticSecurity";
export const EMAIL_SEND_NODE_TYPE = "neurode.emailSend";
export const EMAIL_IMAP_NODE_TYPE = "neurode.emailReadImap";
export const EXECUTE_COMMAND_NODE_TYPE = "neurode.executeCommand";
export const FORM_TRIGGER_NODE_TYPE = "neurode.formTrigger";
export const HTML_NODE_TYPE = "neurode.html";
export const HTTP_REQUEST_NODE_TYPE = "neurode.httpRequest";
export const HUBSPOT_TRIGGER_NODE_TYPE = "neurode.hubspotTrigger";
export const IF_NODE_TYPE = "neurode.if";
export const INTERVAL_NODE_TYPE = "neurode.interval";
export const ITEM_LISTS_NODE_TYPE = "neurode.itemLists";
export const JIRA_NODE_TYPE = "neurode.jira";
export const JIRA_TRIGGER_NODE_TYPE = "neurode.jiraTrigger";
export const MICROSOFT_EXCEL_NODE_TYPE = "neurode.microsoftExcel";
export const MANUAL_TRIGGER_NODE_TYPE = "neurode.manualTrigger";
export const MANUAL_CHAT_TRIGGER_NODE_TYPE =
  "neurode/langchain.manualChatTrigger";
export const CHAT_TRIGGER_NODE_TYPE = "neurode/langchain.chatTrigger";
export const AGENT_NODE_TYPE = "neurode/langchain.agent";
export const OPEN_AI_NODE_TYPE = "neurode/langchain.openAi";
export const OPEN_AI_NODE_MESSAGE_ASSISTANT_TYPE =
  "neurode/langchain.openAi.assistant.message";
export const OPEN_AI_ASSISTANT_NODE_TYPE = "neurode/langchain.openAiAssistant";
export const BASIC_CHAIN_NODE_TYPE = "neurode/langchain.chainLlm";
export const QA_CHAIN_NODE_TYPE = "neurode/langchain.chainRetrievalQa";
export const MICROSOFT_TEAMS_NODE_TYPE = "neurode.microsoftTeams";
export const N8N_NODE_TYPE = "neurode.n8n";
export const NO_OP_NODE_TYPE = "neurode.noOp";
export const STICKY_NODE_TYPE = "neurode.stickyNote";
export const NOTION_TRIGGER_NODE_TYPE = "neurode.notionTrigger";
export const PAGERDUTY_NODE_TYPE = "neurode.pagerDuty";
export const SALESFORCE_NODE_TYPE = "neurode.salesforce";
export const SEGMENT_NODE_TYPE = "neurode.segment";
export const SET_NODE_TYPE = "neurode.set";
export const SCHEDULE_TRIGGER_NODE_TYPE = "neurode.scheduleTrigger";
export const SERVICENOW_NODE_TYPE = "neurode.serviceNow";
export const SLACK_NODE_TYPE = "neurode.slack";
export const SPREADSHEET_FILE_NODE_TYPE = "neurode.spreadsheetFile";
export const SPLIT_IN_BATCHES_NODE_TYPE = "neurode.splitInBatches";
export const START_NODE_TYPE = "neurode.start";
export const SWITCH_NODE_TYPE = "neurode.switch";
export const TELEGRAM_NODE_TYPE = "neurode.telegram";
export const THE_HIVE_TRIGGER_NODE_TYPE = "neurode.theHiveTrigger";
export const QUICKBOOKS_NODE_TYPE = "neurode.quickbooks";
export const WAIT_NODE_TYPE = "neurode.wait";
export const WEBHOOK_NODE_TYPE = "neurode.webhook";
export const WORKABLE_TRIGGER_NODE_TYPE = "neurode.workableTrigger";
export const WORKFLOW_TRIGGER_NODE_TYPE = "neurode.workflowTrigger";
export const EXECUTE_WORKFLOW_NODE_TYPE = "neurode.executeWorkflow";
export const EXECUTE_WORKFLOW_TRIGGER_NODE_TYPE =
  "neurode.executeWorkflowTrigger";
export const WOOCOMMERCE_TRIGGER_NODE_TYPE = "neurode.wooCommerceTrigger";
export const XERO_NODE_TYPE = "neurode.xero";
export const ZENDESK_NODE_TYPE = "neurode.zendesk";
export const ZENDESK_TRIGGER_NODE_TYPE = "neurode.zendeskTrigger";
export const DISCORD_NODE_TYPE = "neurode.discord";
export const EXTRACT_FROM_FILE_NODE_TYPE = "neurode.extractFromFile";
export const CONVERT_TO_FILE_NODE_TYPE = "neurode.convertToFile";
export const DATETIME_NODE_TYPE = "neurode.dateTime";
export const REMOVE_DUPLICATES_NODE_TYPE = "neurode.removeDuplicates";
export const SPLIT_OUT_NODE_TYPE = "neurode.splitOut";
export const LIMIT_NODE_TYPE = "neurode.limit";
export const SUMMARIZE_NODE_TYPE = "neurode.summarize";
export const AGGREGATE_NODE_TYPE = "neurode.aggregate";
export const MERGE_NODE_TYPE = "neurode.merge";
export const MARKDOWN_NODE_TYPE = "neurode.markdown";
export const XML_NODE_TYPE = "neurode.xml";
export const CRYPTO_NODE_TYPE = "neurode.crypto";
export const RSS_READ_NODE_TYPE = "neurode.rssFeedRead";
export const COMPRESSION_NODE_TYPE = "neurode.compression";
export const EDIT_IMAGE_NODE_TYPE = "neurode.editImage";
export const CHAIN_SUMMARIZATION_LANGCHAIN_NODE_TYPE =
  "neurode/langchain.chainSummarization";
export const SIMULATE_NODE_TYPE = "neurode.simulate";
export const SIMULATE_TRIGGER_NODE_TYPE = "neurode.simulateTrigger";
export const AI_TRANSFORM_NODE_TYPE = "neurode.aiTransform";
export const FORM_NODE_TYPE = "neurode.form";
export const SLACK_TRIGGER_NODE_TYPE = "neurode.slackTrigger";
export const TELEGRAM_TRIGGER_NODE_TYPE = "neurode.telegramTrigger";
export const FACEBOOK_LEAD_ADS_TRIGGER_NODE_TYPE =
  "neurode.facebookLeadAdsTrigger";

export const KEEP_AUTH_IN_NDV_FOR_NODES = [
  HTTP_REQUEST_NODE_TYPE,
  WEBHOOK_NODE_TYPE,
  WAIT_NODE_TYPE,
  DISCORD_NODE_TYPE,
  CHAT_TRIGGER_NODE_TYPE,
  FORM_TRIGGER_NODE_TYPE,
];

export const ADD_FORM_NOTICE = "addFormPage";
