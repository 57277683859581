import * as NodeIcon from "@/assets/NodeIcon";
import { useActionsGenerator } from "@/neurodes/utilities/actionGeneration";
import {
  allLatestNodeTypes,
  subcategorizeItems,
} from "@/neurodes/utilities/base";
import { groupNodeTypesByNameAndType } from "@/neurodes/utilities/nodeTypeTransform";
import getNeurodeNodes from "./getNodes";
import { INode } from "../Workflow/interfaces";
import {
  getFilteredParameters,
  getNodeWithProperties,
} from "../utilities/neurode.helper";

class Manager {
  private neurodes: INode[] | null;
  private neurodesDataArray: any[];
  private allGroupedNeurodes: any;
  private latestNeurodes: any;
  categorizedNeurodes: any;

  constructor() {
    this.neurodes = null;
    this.neurodesDataArray = [];
    this.allGroupedNeurodes = {};
    this.latestNeurodes = [];
    this.categorizedNeurodes = {};
  }

  async initialize() {
    if (this.neurodes) return this;
    this.neurodes = await getNeurodeNodes();
    this.neurodesDataArray = Array.isArray(this.neurodes)
      ? this.neurodes.map((node) => ({
          ...node,
          icon: <NodeIcon.CallApiIcon />,
          background: "#faf5f6",
          borderColor: "#7d0019",
        }))
      : [];

    this.allGroupedNeurodes = groupNodeTypesByNameAndType(
      this.neurodesDataArray,
    );
    this.latestNeurodes = allLatestNodeTypes(this.allGroupedNeurodes);
    const { actions: neurodeActions, mergedNodes } =
      useActionsGenerator().generateMergedNodesAndActions(
        this.latestNeurodes,
        [],
      ); // [] needs to replace by httpOnlyCredentials (by credentials store)

    this.categorizedNeurodes = subcategorizeItems(mergedNodes);
    return this;
  }

  getNodeWithProperties(nodeTypeName: string, version?: string) {
    return getNodeWithProperties(
      this.allGroupedNeurodes,
      nodeTypeName,
      version,
    );
  }

  getFilteredParameters(parameters: any, activeNode: any) {
    return getFilteredParameters(parameters, activeNode);
  }
}

const neurodeManager = new Manager();
export default neurodeManager;
