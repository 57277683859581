import { nanoid } from "nanoid";
import {
  AGGREGATE_NODE_TYPE,
  AI_NODE_CREATOR_VIEW,
  AI_SUBCATEGORY,
  AI_TRANSFORM_NODE_TYPE,
  CODE_NODE_TYPE,
  COMPRESSION_NODE_TYPE,
  CONVERT_TO_FILE_NODE_TYPE,
  CORE_NODES_CATEGORY,
  CRYPTO_NODE_TYPE,
  DATETIME_NODE_TYPE,
  DEFAULT_SUBCATEGORY,
  EDIT_IMAGE_NODE_TYPE,
  EMAIL_SEND_NODE_TYPE,
  EXTRACT_FROM_FILE_NODE_TYPE,
  FILTER_NODE_TYPE,
  FLOWS_CONTROL_SUBCATEGORY,
  HELPERS_SUBCATEGORY,
  HITL_SUBCATEGORY,
  HTML_NODE_TYPE,
  HTTP_REQUEST_NODE_TYPE,
  HUMAN_IN_THE_LOOP_CATEGORY,
  IF_NODE_TYPE,
  LIMIT_NODE_TYPE,
  MARKDOWN_NODE_TYPE,
  MERGE_NODE_TYPE,
  REGULAR_NODE_CREATOR_VIEW,
  REMOVE_DUPLICATES_NODE_TYPE,
  RSS_READ_NODE_TYPE,
  SET_NODE_TYPE,
  SPLIT_IN_BATCHES_NODE_TYPE,
  SPLIT_OUT_NODE_TYPE,
  SUMMARIZE_NODE_TYPE,
  TRANSFORM_DATA_SUBCATEGORY,
  TRIGGER_NODE_CREATOR_VIEW,
  WEBHOOK_NODE_TYPE,
  XML_NODE_TYPE,
} from "../constants";
import { NodeView } from "../interfaces";

export function toCamelCase(text: string): string {
  return text
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const formatTriggerActionName = (actionPropertyName: string) => {
  let name = actionPropertyName;
  if (actionPropertyName.includes(".")) {
    name = actionPropertyName.split(".").join(" ");
  }
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};

export const allLatestNodeTypes = (nodeTypes: any[]) => {
  return Object.values(nodeTypes).reduce((allLatestNodeTypes, nodeVersions) => {
    const versionNumbers = Object.keys(nodeVersions).map(Number);
    const typeVersion = Math.max(...versionNumbers);
    const latestNodeVersion = nodeVersions[typeVersion];
    latestNodeVersion.typeVersion = typeVersion;

    if (!latestNodeVersion) return allLatestNodeTypes;

    return [...allLatestNodeTypes, latestNodeVersion];
  }, []);
};

export function transformNodeType(
  node: any,
  subcategory?: string,
  type: "node" | "action" = "node",
): any {
  const createElement = {
    // uuid: uuidv4(),
    uuid: nanoid(),
    key: node.name,
    subcategory:
      subcategory ??
      node.codex?.subcategories?.[CORE_NODES_CATEGORY]?.[0] ??
      DEFAULT_SUBCATEGORY,
    properties: {
      ...node,
    },
    type,
  };

  // return type === 'action'
  // 	? (createElement as ActionCreateElement)
  // 	: (createElement as NodeCreateElement);
  return createElement;
}

export function subcategorizeItems(items: any[]) {
  const WHITE_LISTED_SUBCATEGORIES = [
    CORE_NODES_CATEGORY,
    AI_SUBCATEGORY,
    HUMAN_IN_THE_LOOP_CATEGORY,
  ];
  return items.reduce((acc: any, item) => {
    // Only some subcategories are allowed
    let subcategories: string[] = [DEFAULT_SUBCATEGORY];

    WHITE_LISTED_SUBCATEGORIES.forEach((category) => {
      if (item.codex?.categories?.includes(category)) {
        subcategories = item.codex?.subcategories?.[category] ?? [];
      }
    });

    subcategories.forEach((subcategory: string) => {
      if (!acc[subcategory]) {
        acc[subcategory] = [];
      }
      acc[subcategory].push(transformNodeType(item, subcategory));
    });

    return acc;
  }, {});
}

// not currently in use (to manage different views)
export function RegularView(nodes: any[]) {
  const popularItemsSubcategory = [
    SET_NODE_TYPE,
    CODE_NODE_TYPE,
    DATETIME_NODE_TYPE,
    AI_TRANSFORM_NODE_TYPE,
  ];

  const getSendAndWaitNodes = (nodes: any[]) => {
    return (nodes ?? [])
      .filter((node) =>
        node.codex?.categories?.includes(HUMAN_IN_THE_LOOP_CATEGORY),
      )
      .map((node) => node.name);
  };

  const view: NodeView = {
    value: REGULAR_NODE_CREATOR_VIEW,
    // title: i18n.baseText('nodeCreator.triggerHelperPanel.whatHappensNext'),
    title: "Main Page",
    items: [
      {
        key: DEFAULT_SUBCATEGORY,
        type: "subcategory",
        properties: {
          title: "App Regular Nodes",
          icon: "globe",
          forceIncludeNodes: [RSS_READ_NODE_TYPE, EMAIL_SEND_NODE_TYPE],
        },
      },
      {
        type: "subcategory",
        key: TRANSFORM_DATA_SUBCATEGORY,
        category: CORE_NODES_CATEGORY,
        properties: {
          title: TRANSFORM_DATA_SUBCATEGORY,
          icon: "pen",
          sections: [
            {
              key: "popular",
              // title: i18n.baseText('nodeCreator.sectionNames.popular'),
              title: "Popular",
              items: popularItemsSubcategory,
            },
            {
              key: "addOrRemove",
              // title: i18n.baseText('nodeCreator.sectionNames.transform.addOrRemove'),
              title: "Add or Remove items",
              items: [
                FILTER_NODE_TYPE,
                REMOVE_DUPLICATES_NODE_TYPE,
                SPLIT_OUT_NODE_TYPE,
                LIMIT_NODE_TYPE,
              ],
            },
            {
              key: "combine",
              // title: i18n.baseText('nodeCreator.sectionNames.transform.combine'),
              title: "Combine items",
              items: [
                SUMMARIZE_NODE_TYPE,
                AGGREGATE_NODE_TYPE,
                MERGE_NODE_TYPE,
              ],
            },
            {
              key: "convert",
              // title: i18n.baseText('nodeCreator.sectionNames.transform.convert'),
              title: "Convert items",
              items: [
                HTML_NODE_TYPE,
                MARKDOWN_NODE_TYPE,
                XML_NODE_TYPE,
                CRYPTO_NODE_TYPE,
                EXTRACT_FROM_FILE_NODE_TYPE,
                CONVERT_TO_FILE_NODE_TYPE,
                COMPRESSION_NODE_TYPE,
                EDIT_IMAGE_NODE_TYPE,
              ],
            },
          ],
        },
      },
      {
        type: "subcategory",
        key: FLOWS_CONTROL_SUBCATEGORY,
        category: CORE_NODES_CATEGORY,
        properties: {
          title: FLOWS_CONTROL_SUBCATEGORY,
          icon: "code-branch",
          sections: [
            {
              key: "popular",
              // title: i18n.baseText('nodeCreator.sectionNames.popular'),
              title: "Popular",
              items: [
                FILTER_NODE_TYPE,
                IF_NODE_TYPE,
                SPLIT_IN_BATCHES_NODE_TYPE,
                MERGE_NODE_TYPE,
              ],
            },
          ],
        },
      },
      {
        type: "subcategory",
        key: HELPERS_SUBCATEGORY,
        category: CORE_NODES_CATEGORY,
        properties: {
          title: HELPERS_SUBCATEGORY,
          icon: "toolbox",
          sections: [
            {
              key: "popular",
              // title: i18n.baseText('nodeCreator.sectionNames.popular'),
              title: "Popular",
              items: [
                HTTP_REQUEST_NODE_TYPE,
                WEBHOOK_NODE_TYPE,
                CODE_NODE_TYPE,
              ],
            },
          ],
        },
      },
      // To add node to this subcategory:
      // - add "HITL" to the "categories" property of the node's codex
      // - add "HITL": ["Human in the Loop"] to the "subcategories" property of the node's codex
      // node has to have the "sendAndWait" operation, if a new operation needs to be included here:
      // - update getHumanInTheLoopActions in packages/editor-ui/src/components/Node/NodeCreator/Modes/NodesMode.vue
      {
        type: "subcategory",
        key: HITL_SUBCATEGORY,
        category: HUMAN_IN_THE_LOOP_CATEGORY,
        properties: {
          title: HITL_SUBCATEGORY,
          icon: "user-check",
          sections: [
            {
              key: "sendAndWait",
              // title: i18n.baseText('nodeCreator.sectionNames.sendAndWait'),
              title: "Send and Wait for Response",
              items: getSendAndWaitNodes(nodes),
            },
          ],
        },
      },
    ],
  };

  const hasAINodes = (nodes ?? []).some((node) =>
    node.codex?.categories?.includes(AI_SUBCATEGORY),
  );
  if (hasAINodes)
    view.items.unshift({
      key: AI_NODE_CREATOR_VIEW,
      type: "view",
      properties: {
        // title: i18n.baseText('nodeCreator.aiPanel.langchainAiNodes'),
        title: "Advanced AI Nodes",
        icon: "robot",
        // description: i18n.baseText('nodeCreator.aiPanel.nodesForAi'),
        description:
          "Build autonomous agents, summarize or search documents, etc.",
        borderless: true,
      },
    } as any);

  view.items.push({
    key: TRIGGER_NODE_CREATOR_VIEW,
    type: "view",
    properties: {
      // title: i18n.baseText('nodeCreator.triggerHelperPanel.addAnotherTrigger'),
      title: "Add Another Trigger",
      icon: "bolt",
      // description: i18n.baseText('nodeCreator.triggerHelperPanel.addAnotherTriggerDescription'),
      description:
        "Triggers start your workflow. Workflows can have multiple triggers.",
    },
  });

  return view;
}

export function formatNeurodes(nodes: any[]) {
  return nodes.reduce((acc, node) => {
    acc[node.properties.name] = { ...node, ...node.properties };

    delete acc[node.properties.name].properties;
    return acc;
  }, {});
}
