import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { userSlice } from '@/redux/User';
import { 
  createCredential, 
  updateCredential, 
  fetchCredentialTypes, 
  testCredential,
  selectCredentialTypes,
  selectCredentials,
  selectLoadingStates,
  selectTestResult
} from '../store/credentials.store';
import { ICredentialType, ICredentialsResponse } from '../types/credentials';
import { CredentialConfig } from './CredentialConfig';

export default function CredentialEdit() {
  console.log('CredentialEdit component rendering');
  
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string>('');
  const [credentialData, setCredentialData] = useState<Record<string, any>>({});
  const [isTesting, setIsTesting] = useState(false);

  const credentialTypes = useSelector(selectCredentialTypes);
  const credentials = useSelector(selectCredentials);
  const loading = useSelector(selectLoadingStates);
  const testResult = useSelector(selectTestResult);
  const isLoading = loading.types || loading.credentials;

  const showToast = (message: string, type: 'success' | 'error') => {
    dispatch(userSlice.actions.setToastPopUp({
      show: true,
      message,
      toastType: type
    }));
  };

  console.log('Current state:', {
    credentialTypes,
    credentials,
    loading,
    testResult,
    isLoading,
    id,
    selectedType,
    credentialData
  });

  useEffect(() => {
    console.log('Fetching credential types...');
    dispatch(fetchCredentialTypes());
  }, [dispatch]);

  useEffect(() => {
    console.log('Checking for existing credential...', { id, credentials });
    if (id && credentials && credentials[id]) {
      const credential = credentials[id];
      console.log('Found credential:', credential);
      setSelectedType(credential.type);
      setCredentialData(credential.data || {});
    }
  }, [id, credentials]);

  const hasRequiredData = () => {
    if (!selectedType || !credentialData) return false;
    
    const credentialType = credentialTypes[selectedType];
    if (!credentialType) return false;

    // For OAuth2 credentials, check if we have access token
    if (credentialType.name.toLowerCase().includes('oauth2')) {
      return !!credentialData.accessToken;
    }

    // For other credentials, check if we have any data
    return Object.keys(credentialData).length > 0;
  };

  const handleSave = async () => {
    try {
      console.log('Saving credential...', {
        id,
        selectedType,
        credentialData
      });
      
      const selectedCredentialType = credentialTypes[selectedType];
      const credentialPayload = {
        name: selectedCredentialType?.displayName || selectedType,
        type: selectedType,
        data: credentialData
      };

      if (id) {
        await dispatch(updateCredential({ id, data: credentialPayload })).unwrap();
        showToast('Credential updated successfully', 'success');
      } else {
        await dispatch(createCredential(credentialPayload)).unwrap();
        showToast('Credential created successfully', 'success');
      }
      
      // Reset form after successful save
      if (!id) {
        setSelectedType('');
        setCredentialData({});
      }
    } catch (err) {
      console.error('Error saving credential:', err);
      setError('Failed to save credential');
      showToast('Failed to save credential', 'error');
    }
  };

  const handleTest = async () => {
    try {
      setIsTesting(true);
      console.log('Testing credential...', {
        type: selectedType,
        data: credentialData
      });
      await dispatch(testCredential({ type: selectedType, data: credentialData })).unwrap();
      showToast('Credential test successful', 'success');
    } catch (err) {
      console.error('Error testing credential:', err);
      setError('Failed to test credential');
      showToast('Failed to test credential', 'error');
    } finally {
      setIsTesting(false);
    }
  };

  const handleCancel = () => {
    setSelectedType('');
    setCredentialData({});
    setError(null);
  };

  const handleTypeChange = (type: string) => {
    console.log('Changing credential type:', type);
    setSelectedType(type);
    setCredentialData({});
  };

  const handleDataUpdate = (data: Record<string, any>) => {
    console.log('Updating credential data:', data);
    setCredentialData(data);
  };

  if (isLoading) {
    console.log('Showing loading state');
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  const credentialTypesArray = Object.values(credentialTypes || {}) as ICredentialType[];
  const selectedCredentialType = credentialTypes[selectedType] as ICredentialType | undefined;

  console.log('Rendering form with:', {
    credentialTypesArray,
    selectedCredentialType
  });

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-semibold mb-4">
          {id ? 'Edit Credential' : 'Create New Credential'}
        </h1>

        {error && (
          <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {testResult && testResult.message && (
          <div className={`${testResult.status === 'OK' ? 'bg-green-50 border-green-400 text-green-700' : 'bg-red-50 border-red-400 text-red-700'} px-4 py-3 rounded mb-4 border`} role="alert">
            <span className="block sm:inline">{testResult.message}</span>
          </div>
        )}

        <CredentialConfig
          mode={id ? 'edit' : 'new'}
          selectedType={selectedType}
          onTypeChange={handleTypeChange}
          credentialTypes={credentialTypesArray}
          credentialData={credentialData}
          onDataUpdate={handleDataUpdate}
          disabled={!!id}
        />

        <div className="flex gap-2 mt-6">
          <button 
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSave}
            disabled={!hasRequiredData()}
          >
            Save
          </button>
          {selectedType && (
            <button 
              className="px-4 py-2 border border-blue-600 text-blue-600 rounded hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleTest}
              disabled={isTesting || !credentialData}
            >
              {isTesting ? 'Testing...' : 'Test'}
            </button>
          )}
          <button 
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
} 