import React from 'react';
import { ICredentialType, ICredentialProperty } from '../types/credentials';
import { shouldDisplayProperty } from '../utilities/credentialUtils';

interface CredentialInputsProps {
  credentialType: ICredentialType;
  credentialData: Record<string, any>;
  onDataUpdate: (data: Record<string, any>) => void;
  showValidationWarnings?: boolean;
}

export function CredentialInputs({
  credentialType,
  credentialData,
  onDataUpdate,
  showValidationWarnings = false,
}: CredentialInputsProps) {
  const handleInputChange = (property: ICredentialProperty, value: any) => {
    onDataUpdate({
      ...credentialData,
      [property.name]: value,
    });
  };

  const renderInput = (property: any) => {
    if (property.type === 'hidden') return null;

    if (!shouldDisplayProperty(property, credentialData)) {
      return null;
    }

    const value = credentialData[property.name] || property.default || '';
    const isError = showValidationWarnings && property.required && !credentialData[property.name];

    const commonClasses = `w-full px-3 py-2 border rounded-md ${
      isError 
        ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
        : 'border-gray-300 focus:border-primary focus:ring-primary'
    }`;

    switch (property.type) {
      case 'string':
        return (
          <div key={property.name} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {property.displayName}
              {property.required && <span className="text-red-500">*</span>}
            </label>
            <input
              type={property.typeOptions?.password ? 'password' : 'text'}
              value={value}
              onChange={(e) => handleInputChange(property, e.target.value)}
              required={property.required}
              className={commonClasses}
            />
            {isError && (
              <p className="mt-1 text-sm text-red-600">{property.displayName} is required</p>
            )}
            {property.description && !isError && (
              <p className="mt-1 text-sm text-gray-500">{property.description}</p>
            )}
          </div>
        );

      case 'number':
        return (
          <div key={property.name} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {property.displayName}
              {property.required && <span className="text-red-500">*</span>}
            </label>
            <input
              type="number"
              value={value}
              onChange={(e) => handleInputChange(property, e.target.value)}
              required={property.required}
              className={commonClasses}
            />
            {isError && (
              <p className="mt-1 text-sm text-red-600">{property.displayName} is required</p>
            )}
            {property.description && !isError && (
              <p className="mt-1 text-sm text-gray-500">{property.description}</p>
            )}
          </div>
        );

      case 'boolean':
        return (
          <div key={property.name} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {property.displayName}
              {property.required && <span className="text-red-500">*</span>}
            </label>
            <select
              value={value.toString()}
              onChange={(e) => handleInputChange(property, e.target.value === 'true')}
              required={property.required}
              className={commonClasses}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            {property.description && (
              <p className="mt-1 text-sm text-gray-500">{property.description}</p>
            )}
          </div>
        );

      case 'select':
        return (
          <div key={property.name} className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {property.displayName}
              {property.required && <span className="text-red-500">*</span>}
            </label>
            <select
              value={value}
              onChange={(e) => handleInputChange(property, e.target.value)}
              required={property.required}
              className={commonClasses}
            >
              {property.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
            {property.description && (
              <p className="mt-1 text-sm text-gray-500">{property.description}</p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      {credentialType.properties.map(renderInput)}
      
      {credentialType.documentationUrl && (
        <div className="mt-4">
          <a
            href={credentialType.documentationUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 text-sm"
          >
            Documentation
          </a>
        </div>
      )}
    </div>
  );
} 