import React, { Fragment, useRef, useState, useEffect } from "react";
import SideNav from "../../components/layoutElements/sideNav";
import "./templates.css";
import Template from "../../components/layoutElements/template";
import { axiosGet } from "../../services/axiosService";
import { handleSearch } from "../../utils/handleSearch";
import { useNavigate } from "react-router-dom";
import environment from "../../constants/Environment";
import OmniSideNav from "../../components/layoutElements/OmniSideNav";

type routeType = "/dashboard" | "/integration" | "/analytics" | "/chats" | "/audience" | "/settings";
import { TabTitle } from "../../utils/TitleFuntion";

const Templates = () => {
  const router = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("All Templates");
  const [search, setSearch] = useState<any>("");
  const [premades, setPremades] = useState<any>([]);
  const List = ["All Templates", "Web", "Webhook", "Campaign"];
  const categories = ["Web", "Webhook", "Campaign"];
  const [showAll, setShowAll] = useState<string>("");
  let menuRef = useRef<any>();

  const setRoute = (routeInput: routeType = "/dashboard") => router(routeInput);

  useEffect(() => {
    TabTitle(" | Templates");
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const getPremades = async () => {
    try {
      const res = await axiosGet(`admin/getPremades`);
      setPremades(res.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    console.log(premades);
  };

  useEffect(() => {
    getPremades();
  }, []);

  return (
    <Fragment>
      <div className="vertical-overlay"></div>
      {/* {environment().isAI ? <SideNav /> : <OmniSideNav setRoute={setRoute} />} */}
      <div className="templates-wrapper">
        <div className="nav_section">
          <div className="inner_section">
            <div className="welcome-user">
              <div className="welcome-head">
                <h1>Welcome tiger</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="cards-tables">
          <div className="fx jstfy-sp-btw al-cnt">
            <div className="dashboard_header no-m templates-header fx fx-d-col gap-5 ">
              <h1>Templates</h1>
              <p>Elit donec nisi mollis platea enim, suspendisse amet ac. Facilisis in.</p>
            </div>
            <div className="templates-drpdwn-search fx gap-20">
              <div className="templates-all flex-cnt p-relative" ref={menuRef} onClick={() => setOpen(!open)}>
                <span className="flex-cnt" style={{ gap: "60px" }}>
                  {selected} <ArrowIcon />
                </span>
                {open && (
                  <ul className="p-absolute ">
                    {List.map((menu) => (
                      <li
                        onClick={(e) => {
                          setSelected(menu);
                          setOpen(false);
                        }}
                      >
                        {menu}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="thin-line"></div>
              <div className="templates-search flex-cnt gap-20">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search Template"
                  className="bd-none no-bg"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="thin-line mt-30"></div>
          {categories.map((category) => {
            if (selected == category || selected == "All Templates") {
              return (
                <>
                  <div className="mt-20 fx al-cnt jstfy-sp-btw">
                    <div className="temp-heading fx al-cnt gap-10">
                      <h1>{category}</h1>{" "}
                      <div className="count-bx">
                        {
                          premades.filter((pre) => {
                            return handleSearch(search, pre.name) && pre.type == category.toLowerCase();
                          }).length
                        }
                      </div>
                    </div>
                    {premades.filter((pre) => {
                      return pre.type == category.toLowerCase();
                    }).length > 5 && (
                      <span
                        className="c-more cp"
                        onClick={() => {
                          setShowAll(showAll == category ? "" : category);
                          setSelected(showAll == category ? "All Templates" : category);
                        }}
                      >
                        {showAll == category ? "See Less" : "See More"}
                      </span>
                    )}
                  </div>
                  <Template
                    premade={premades.filter((pre) => {
                      return handleSearch(search, pre.name) && pre.type == category.toLowerCase();
                    })}
                    showAll={showAll == category}
                  />
                </>
              );
            }
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Templates;

export function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <g clipPath="url(#clip0_828_2254)">
        <path
          d="M5.53331 0C6.91244 0 8.23508 0.547855 9.21027 1.52304C10.1855 2.49823 10.7333 3.82087 10.7333 5.2C10.7333 6.488 10.2613 7.672 9.48531 8.584L9.70131 8.8H10.3333L14.3333 12.8L13.1333 14L9.13331 10V9.368L8.91731 9.152C7.97375 9.95742 6.77388 10.3999 5.53331 10.4C4.15419 10.4 2.83155 9.85215 1.85636 8.87696C0.881168 7.90177 0.333313 6.57913 0.333313 5.2C0.333313 3.82087 0.881168 2.49823 1.85636 1.52304C2.83155 0.547855 4.15419 0 5.53331 0ZM5.53331 1.6C3.53331 1.6 1.93331 3.2 1.93331 5.2C1.93331 7.2 3.53331 8.8 5.53331 8.8C7.53331 8.8 9.13331 7.2 9.13331 5.2C9.13331 3.2 7.53331 1.6 5.53331 1.6Z"
          fill="black"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_828_2254">
          <rect width="14" height="14" fill="white" transform="translate(0.333313)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clipPath="url(#clip0_564_15372)">
      <path
        d="M14.8721 7.16572L10.3724 2.16599C10.2764 2.06 10.1424 2 10.0004 2H7.00054C6.90395 2.0001 6.80946 2.02809 6.72839 2.08059C6.64733 2.1331 6.58315 2.20789 6.54356 2.29598C6.46356 2.47697 6.49656 2.68796 6.62856 2.83395L10.8273 7.4997L6.62856 12.1644C6.49656 12.3114 6.46256 12.5224 6.54356 12.7024C6.62456 12.8834 6.80355 12.9994 7.00054 12.9994H10.0004C10.1424 12.9994 10.2764 12.9384 10.3724 12.8344L14.8721 7.83468C15.0431 7.64469 15.0431 7.35471 14.8721 7.16572Z"
        fill="#848484"
      />
      <path
        d="M8.37211 7.16572L3.87235 2.16599C3.77636 2.06 3.64236 2 3.50037 2H0.500535C0.403954 2.0001 0.309457 2.02809 0.228394 2.08059C0.147331 2.1331 0.0831502 2.20789 0.0435599 2.29598C-0.0364357 2.47697 -0.00343754 2.68796 0.128555 2.83395L4.32733 7.4997L0.128555 12.1644C-0.00343754 12.3114 -0.0374357 12.5224 0.0435599 12.7024C0.124556 12.8834 0.303546 12.9994 0.500535 12.9994H3.50037C3.64236 12.9994 3.77636 12.9384 3.87235 12.8344L8.37211 7.83468C8.5431 7.64469 8.5431 7.35471 8.37211 7.16572Z"
        fill="#848484"
      />
    </g>
    <defs>
      <clipPath id="clip0_564_15372">
        <rect width={15} height={15} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
