import * as NodeIcon from "@/assets/NodeIcon";
import NODE_INFO, { NodeInfoI } from "@/constants/NodeInfo";
import { DEFAULT_SUBCATEGORY, HITL_SUBCATEGORY } from "@/neurodes/constants";
import neurodeManager from "@/neurodes/Nodes/manager";
import { formatNeurodes } from "@/neurodes/utilities/base";
export const SECTIONS = [
  "triggers",
  "actions",
  "apps",
  "extras",
  // "defaultNeurodes",
  // "hitlNeurodes",
] as const;
export type SectionsType = (typeof SECTIONS)[number];

export interface Node {
  name: string;
  icon: React.ReactNode;
  background: string;
  borderColor: string;
  tooltip?: React.ReactNode;
  displayName?: string;
  description?: string;
  codex?: any;
  iconUrl?: string | Object;
}

export interface NodeWithId extends Node {
  id: string;
}

const _NODES_DATA: Record<string, Node> = {
  ask_question: {
    name: "Ask Question",
    icon: <NodeIcon.AskQuestionIcon />,
    background: "#7479F20D",
    borderColor: "#7479F21A",
  },
  button: {
    name: "Single select",
    icon: <NodeIcon.SingleSelectIcon />,
    background: "#F371710D",
    borderColor: "#F371711A",
  },
  email: {
    name: "Get Email ID",
    icon: <NodeIcon.GetEmailIcon />,
    background: "#BB3DDB0D",
    borderColor: "#BB3DDB1A",
  },
  phone: {
    name: "Get Phone No.",
    icon: <NodeIcon.GetPhoneIcon />,
    background: "#19B21F0D",
    borderColor: "#19B21F0D",
  },
  username: {
    name: "Get Username",
    icon: <NodeIcon.GetUsernameIcon />,
    background: "#99620F0D",
    borderColor: "#99620F0D",
  },
  address: {
    name: "Get Address",
    icon: <NodeIcon.GetAddressIcon />,
    background: "#5640D60D",
    borderColor: "#5640D61A",
  },
  result: {
    name: "Calculate Result",
    icon: <NodeIcon.CalculateResultIcon />,
    background: "#923FD20D",
    borderColor: "#923FD21A",
  },
  sendMessage: {
    name: "Send message",
    icon: <NodeIcon.SendMessageIcon />,
    background: "#2989CF0D",
    borderColor: "#2989CF1A",
  },
  socialData: {
    name: "Webhook",
    icon: <NodeIcon.WebHookIcon />,
    background: "#C73A630D",
    borderColor: "#C73A631A",
    tooltip: (
      <p>
        Webhooks are a way for one application to provide{" "}
        <strong>real-time information</strong> to another application. Webhooks
        can be used to automatically trigger actions or events based on specific
        user behaviour or data changes. This allows for more efficient and{" "}
        <strong> automated marketing campaigns</strong>.
      </p>
    ),
  },
  segment: {
    name: "Audience",
    icon: <NodeIcon.AudienceIcon />,
    background: "#B023910D",
    borderColor: "#B023911A",
  },
  liveChat: {
    name: "Live Chat",
    icon: <NodeIcon.LiveChatIcon />,
    background: "#B023910D",
    borderColor: "#B023911A",
  },
  sendEmail: {
    name: "Send Email",
    icon: <NodeIcon.SendEmailIcon />,
    background: "#BB3DDB0D",
    borderColor: "#BB3DDB1A",
  },
  sendSMS: {
    name: "Send SMS",
    icon: <NodeIcon.SendSMSIcon />,
    background: "#29A2B20D",
    borderColor: "#29A2B21A",
  },
  hitApi: {
    name: "Call API",
    icon: <NodeIcon.CallApiIcon />,
    background: "#C547470D",
    borderColor: "#C547471A",
    tooltip: (
      <p>
        An <strong>API call</strong>, or an <strong>API request</strong>, allows
        one application to request data or services from another application.
        You can add the data fed into your app directly on the Omniengage app to
        trigger an action like <strong>- push, delete</strong>.
      </p>
    ),
  },
  delayAction: {
    name: "Delay action",
    icon: <NodeIcon.DelayActionIcon />,
    background: "#85A6000D",
    borderColor: "#85A6001A",
  },
  pushNotification: {
    name: "Notification",
    icon: <NodeIcon.NotificationIcon />,
    background: "#DC3C2F0D",
    borderColor: "#DC3C2F1A",
  },
  abTest: {
    name: "A/B Testing",
    icon: <NodeIcon.ABTestingIcon />,
    background: "#00A1E20D",
    borderColor: "#00A1E21A",
    tooltip: (
      <p>
        By comparing two versions of a{" "}
        <strong>marketing campaign, A/B testing</strong> allows you to analyze
        data to make decisions about which <strong>campaign</strong> is
        performing better.
      </p>
    ),
  },
  condition: {
    name: "Conditions",
    icon: <NodeIcon.ConditionIcon />,
    background: "#4A64A80D",
    borderColor: "#4A64A81A",
  },
  assignees: {
    name: "Assignee",
    icon: <NodeIcon.AssigneesIcon />,
    background: "#8C33D20D",
    borderColor: "#8C33D21A",
  },
  whatsApp: {
    name: "Whatsapp",
    icon: <NodeIcon.WhatsappIcon />,
    background: "#26B53C1A",
    borderColor: "#26B53C1A",
  },
  hubspot: {
    name: "Hubspot",
    icon: <NodeIcon.HubspotIcon />,
    background: "#FF7A590D",
    borderColor: "#FF7A591A",
  },
  openai: {
    name: "Open AI",
    icon: <NodeIcon.OpenAIIcon />,
    background: "#0000000D",
    borderColor: "#0000001A",
  },
  googleSheet: {
    name: "Google Sheets",
    icon: <NodeIcon.GoogleSheetIcon />,
    background: "#23A5660D",
    borderColor: "#23A5661A",
  },
  linkedIn: {
    name: "Linkedin",
    background: "#29A2B20D",
    icon: <NodeIcon.LinkedInIcon />,
    borderColor: "#29A2B21A",
  },
  sticky: {
    name: "Sticky notes",
    icon: <NodeIcon.StickyIcon />,
    background: "#D9880D0D",
    borderColor: "#D9880D1A",
  },
  salesForce: {
    name: "Salesforce",
    background: " #0077B50D",
    icon: <NodeIcon.SalesforceIcon />,
    borderColor: "#0074D21A",
  },
};

class NodeSelection {
  ACTIONS: Array<string> = [
    "sendEmail",
    "sendSMS",
    "hitApi",
    "delayAction",
    "pushNotification",
    "abTest",
    "condition",
    "assignees",
    // "salesForce",
    // "whatsApp",
  ];
  TRIGGERS: Array<string> = [
    "ask_question",
    "button",
    "email",
    "phone",
    "username",
    "address",
    "result",
    "sendMessage",
    // "socialData",
    // "segment",
    // "liveChat",
  ];
  APPS: Array<string> = ["openai", "linkedIn"];
  EXTRAS: Array<string> = ["sticky"];

  private DEFAULT_NEURODES_DATA: any;
  private HITL_NEURODES_DATA: any;

  DEFAULT_NEURODES: Array<string>;
  HITL_NEURODES: Array<string>;

  NODE_DATA: Record<string, Node> = _NODES_DATA;

  NODE_INFO: Record<string, NodeInfoI>;

  constructor() {
    this.DEFAULT_NEURODES_DATA = {};
    this.HITL_NEURODES_DATA = {};
    this.DEFAULT_NEURODES = [];
    this.HITL_NEURODES = [];
    this.NODE_INFO = NODE_INFO;
  }

  async initialize() {
    await neurodeManager.initialize();
    this.DEFAULT_NEURODES_DATA = formatNeurodes(
      neurodeManager.categorizedNeurodes[DEFAULT_SUBCATEGORY],
    );
    this.HITL_NEURODES_DATA = formatNeurodes(
      neurodeManager.categorizedNeurodes[HITL_SUBCATEGORY],
    );

    this.DEFAULT_NEURODES = Object.keys(this.DEFAULT_NEURODES_DATA);
    this.HITL_NEURODES = Object.keys(this.HITL_NEURODES_DATA);

    this.NODE_DATA = {
      ...this.NODE_DATA,
      ...this.DEFAULT_NEURODES_DATA,
      ...this.HITL_NEURODES_DATA,
    };

    [...this.DEFAULT_NEURODES, ...this.HITL_NEURODES].forEach((type) => {
      NODE_INFO[type] = {
        name: this.NODE_DATA[type].displayName || this.NODE_DATA[type].name,
        description: this.NODE_DATA[type].description || "",
      };
    });

    return this;
  }
}

export const nodeSelection = new NodeSelection();
