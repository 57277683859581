export interface NodeInfoI {
  name: string;
  description: string;
}

const NODE_INFO: Record<string, NodeInfoI> = {
  campaign: {
    name: "Campaign",
    description: "Send campaign",
  },
  ask_question: {
    name: "Ask Question",
    description: "Using this step you can ask any custom question.",
  },
  button: {
    name: "Single select",
    description:
      "Using this step you can create multiple option to choose between for user.",
  },
  email: {
    name: "Get Email ID",
    description: "Using this step you can ask user for his/her email.",
  },
  phone: {
    name: "Get Phone No.",
    description: "Using this step you can ask user for his/her phone number.",
  },
  username: {
    name: "Get Username",
    description: "Using this step you can ask user for his/her name.",
  },
  address: {
    name: "Get Address",
    description: "Using this step you can ask user for his/her address.",
  },
  result: {
    name: "Calculate Result",
    description:
      "Using this step you can calculate result of basic operations and store it in a variable.",
  },
  sendMessage: {
    name: "Send message",
    description:
      "Using this step You can send a message to the user informing about the flow control.",
  },
  socialData: {
    name: "Webhook",
    description:
      "This step can be used trigger a flow based on events and data received in webhook.",
  },
  segment: {
    name: "Audience",
    description: "Segment node.",
  },
  sendEmail: {
    name: "Send Email",
    description:
      "Using this step you can send E-mail to user at that instance using smtp configuration.",
  },
  sendSMS: {
    name: "Send SMS",
    description:
      "Using this step you can send mobile SMS to user at that instance using Twilio configuration.",
  },
  hitApi: {
    name: "Call API",
    description:
      "Using this step you can trigger an API call with all API methods such as GET, POST, PUT, PATCH and DELETE.",
  },
  delayAction: {
    name: "Delay action",
    description:
      "Using this action, you can delay the flow of the sequence by a specific time.",
  },
  pushNotification: {
    name: "Notification",
    description:
      "This step can be used to send Push Notifications to the User using Firebase Configuration.",
  },
  abTest: {
    name: "A/B Testing",
    description:
      "Using this step you can perform A/B testing to divide the flow for various users based on percentages.",
  },
  condition: {
    name: "Conditions",
    description:
      "Using this action, you can add a Conditional Operation to trigger different flows for different conditions.",
  },
  assignees: {
    name: "Assignee",
    description: "Assignee to user.",
  },
  whatsApp: {
    name: "WhatsApp",
    description:
      "Using this step you can send Whats app messages to the user, a whatsapp configuration is required.",
  },
  envoie: {
    name: "Envoie",
    description:
      "Using this step you can fetch templates from Envoie and use it to send Email to users.",
  },
  openai: {
    name: "Open AI",
    description: "Perform OpenAi actions.",
  },
  linkedIn: {
    name: "Linkedin",
    description: "Perform LinkedIn actions.",
  },
  liveChat: {
    name: "Live Chat",
    description: "Using this step you can Connect and send messages to User.",
  },
  sticky: {
    name: "Sticky Node",
    description: "Write your nots",
  },
  unknown: {
    name: "Unknown",
    description: "Unknown node",
  },
  salesForce: {
    name: "Salesforce",
    description: "Perform Salesforce actions.",
  },
};

export default NODE_INFO;
