import React, { useEffect, useMemo, useState } from 'react';
import { ICredentialType } from '../types/credentials';
import { CredentialInputs } from './CredentialInputs';
import { getCredentialProperties } from '../utilities/credentialUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import CommonOAuthButton from './CommonOAuthButton';

interface CredentialConfigProps {
  mode: 'new' | 'edit';
  selectedType: string;
  onTypeChange: (type: string) => void;
  credentialTypes: ICredentialType[];
  credentialData: Record<string, any>;
  onDataUpdate: (data: Record<string, any>) => void;
  disabled?: boolean;
  showValidationWarnings?: boolean;
}

export function CredentialConfig({
  mode,
  selectedType,
  onTypeChange,
  credentialTypes = [],
  credentialData,
  onDataUpdate,
  disabled = false,
  showValidationWarnings = false,
}: CredentialConfigProps) {
  const [error, setError] = useState<string | null>(null);

  const credentialTypeMap = useMemo(
    () => new Map((credentialTypes || []).map(type => [type.name, type])),
    [credentialTypes]
  );

  const selectedCredentialType = credentialTypeMap.get(selectedType);

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onTypeChange(event.target.value);
  };

  const handleOAuthSuccess = (response: any) => {
    onDataUpdate({
      ...credentialData,
      ...response,
    });
  };

  const isGoogleOAuth = selectedCredentialType?.name === 'googleOAuth2Api' || 
                       selectedCredentialType?.extends?.includes('googleOAuth2Api');

  const isGmailOAuth = selectedCredentialType?.name === 'gmailOAuth2Api' || 
                      selectedCredentialType?.extends?.includes('gmailOAuth2Api');

  const isOAuth2Type = (type: ICredentialType): boolean => {
    return (
      type.name.toLowerCase().includes('oauth2') ||
      (Array.isArray(type.extends) && type.extends.some((ext: string) => ext.toLowerCase().includes('oauth2')))
    );
  };

  const renderAuthButton = () => {
    if (!selectedCredentialType) return null;

    if (isOAuth2Type(selectedCredentialType)) {
      if (isGoogleOAuth || isGmailOAuth) {
        return (
          <CommonOAuthButton
            credentialType={selectedCredentialType.name}
            serviceName={selectedCredentialType.displayName}
            icon="google"
            onSuccess={handleOAuthSuccess}
            onError={(error) => setError(error)}
            disabled={disabled}
          />
        );
      }

      if (selectedCredentialType.name.toLowerCase().includes('salesforce')) {
        return (
          <CommonOAuthButton
            credentialType={selectedCredentialType.name}
            serviceName={selectedCredentialType.displayName}
            icon="salesforce"
            onSuccess={handleOAuthSuccess}
            onError={(error) => setError(error)}
            disabled={disabled}
          />
        );
      }

      return (
        <CommonOAuthButton
          credentialType={selectedCredentialType.name}
          serviceName={selectedCredentialType.displayName}
          icon="generic"
          onSuccess={handleOAuthSuccess}
          onError={(error) => setError(error)}
          disabled={disabled}
        />
      );
    }

    return null;
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div>
        <label 
          htmlFor="credential-type" 
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Credential Type<span className="text-red-500">*</span>
        </label>
        <select
          id="credential-type"
          value={selectedType}
          onChange={handleTypeChange}
          disabled={disabled || mode === 'edit'}
          required
          className={`w-full px-3 py-2 border rounded-md ${
            disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'
          } ${
            showValidationWarnings && !selectedType 
              ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
              : 'border-gray-300 focus:border-primary focus:ring-primary'
          }`}
        >
          <option value="">Select a type</option>
          {credentialTypes.map((type) => (
            <option key={type.name} value={type.name}>
              {type.displayName}
            </option>
          ))}
        </select>
        {showValidationWarnings && !selectedType && (
          <p className="mt-1 text-sm text-red-600">Credential Type is required</p>
        )}
      </div>

      {renderAuthButton()}

      {selectedCredentialType && (
        <CredentialInputs
          credentialType={selectedCredentialType}
          credentialData={credentialData}
          onDataUpdate={onDataUpdate}
          showValidationWarnings={showValidationWarnings}
        />
      )}
    </div>
  );
} 