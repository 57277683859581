import axios from 'axios';
import { API_BASE_URL } from '@/config';

const CREDENTIALS_API = `${API_BASE_URL}/api/credentials`;

export interface ICredentialType {
  name: string;
  displayName: string;
  properties: Array<{
    name: string;
    displayName: string;
    type: string;
    required: boolean;
    default?: any;
    description?: string;
    options?: Array<{ name: string; value: any }>;
    typeOptions?: {
      password?: boolean;
      multiline?: boolean;
      rows?: number;
    };
  }>;
  extends?: string[];
  documentationUrl?: string;
}

export interface ICredential {
  id: string;
  name: string;
  type: string;
  data: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

class CredentialService {
  async getTypes(): Promise<ICredentialType[]> {
    const response = await axios.get(`${CREDENTIALS_API}/types`);
    return response.data;
  }

  async getAll(): Promise<ICredential[]> {
    const response = await axios.get(CREDENTIALS_API);
    return response.data;
  }

  async getById(id: string): Promise<ICredential> {
    const response = await axios.get(`${CREDENTIALS_API}/${id}`);
    return response.data;
  }

  async create(data: Partial<ICredential>): Promise<ICredential> {
    const response = await axios.post(CREDENTIALS_API, data);
    return response.data;
  }

  async update(id: string, data: Partial<ICredential>): Promise<ICredential> {
    const response = await axios.patch(`${CREDENTIALS_API}/${id}`, data);
    return response.data;
  }

  async delete(id: string): Promise<void> {
    await axios.delete(`${CREDENTIALS_API}/${id}`);
  }

  async test(type: string, data: Record<string, any>): Promise<{ status: string; message: string }> {
    const response = await axios.post(`${CREDENTIALS_API}/test`, { type, data });
    return response.data;
  }
}

export default new CredentialService(); 