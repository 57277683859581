import { memoize, startCase } from "lodash";
import {
  AI_CATEGORY_ROOT_NODES,
  AI_CATEGORY_TOOLS,
  AI_SUBCATEGORY,
  CUSTOM_API_CALL_KEY,
  HTTP_REQUEST_NODE_TYPE,
} from "../constants";
import { getCredentialOnlyNodeType } from "../Nodes/credentialOnlyNodes";
import { formatTriggerActionName } from "./base";

export function useActionsGenerator() {
  function generateNodeActions(node: any | undefined) {
    if (!node) return [];
    if (
      node.codex?.subcategories?.AI?.includes(AI_CATEGORY_TOOLS) &&
      !node.codex?.subcategories?.AI?.includes(AI_CATEGORY_ROOT_NODES)
    )
      return [];
    return [
      ...triggersCategory(node),
      ...operationsCategory(node),
      ...resourceCategories(node),
      ...modeCategory(node),
    ];
  }

  function filterActions(actions: any[]) {
    if (actions.length <= 1) return [];
    return actions.filter((action: any, _: number, arr: any[]) => {
      const isApiCall = action.actionKey === CUSTOM_API_CALL_KEY;
      if (isApiCall) return false;

      const isPlaceholderTriggerAction =
        action.actionKey === PLACEHOLDER_RECOMMENDED_ACTION_KEY;
      return (
        !isPlaceholderTriggerAction ||
        (isPlaceholderTriggerAction && arr.length > 1)
      );
    });
  }

  function getSimplifiedNodeType(node: any): any {
    const {
      displayName,
      defaults,
      description,
      name,
      group,
      icon,
      iconUrl,
      iconColor,
      badgeIconUrl,
      outputs,
      codex,
      borderColor,
      background,
      typeVersion,
    } = node;

    return {
      displayName,
      defaults,
      description,
      name,
      group,
      icon,
      iconColor,
      iconUrl,
      badgeIconUrl,
      outputs,
      codex,
      borderColor,
      background,
      typeVersion,
    };
  }

  function generateMergedNodesAndActions(
    nodeTypes: any[],
    httpOnlyCredentials: any[],
  ) {
    const visibleNodeTypes = [...nodeTypes];
    const actions: any = {};
    const mergedNodes: any[] = [];
    visibleNodeTypes
      .filter((node) => !node.group.includes("trigger"))
      .forEach((app) => {
        const appActions = generateNodeActions(app);
        actions[app.name] = appActions;

        if (app.name === HTTP_REQUEST_NODE_TYPE) {
          const credentialOnlyNodes = httpOnlyCredentials.map(
            (credentialType) => {
              const credsOnlyNode = getCredentialOnlyNodeType(
                app,
                credentialType,
              );
              if (credsOnlyNode) return getSimplifiedNodeType(credsOnlyNode);
              return null;
            },
          );

          const filteredNodes = credentialOnlyNodes.filter(
            (node) => node !== null,
          );

          mergedNodes.push(...filteredNodes);
        }

        mergedNodes.push(getSimplifiedNodeType(app));
      });

    visibleNodeTypes
      .filter((node) => node.group.includes("trigger"))
      .forEach((trigger) => {
        const normalizedName = trigger.name.replace("Trigger", "");
        const triggerActions = generateNodeActions(trigger);
        const appActions = actions?.[normalizedName] || [];
        const app = mergedNodes.find((node) => node.name === normalizedName);

        if (app && appActions?.length > 0) {
          const mergedActions = filterActions([
            ...appActions,
            ...triggerActions,
          ]);
          actions[normalizedName] = mergedActions;

          app.description = trigger.description;
        } else {
          actions[trigger.name] = filterActions(triggerActions);
          mergedNodes.push(getSimplifiedNodeType(trigger));
        }
      });

    return {
      actions,
      mergedNodes,
    };
  }

  return {
    generateMergedNodesAndActions,
  };
}

const PLACEHOLDER_RECOMMENDED_ACTION_KEY = "placeholder_recommended";

// function translate(...args: any) {
//   return i18n.baseText(...args);
// }

// const cachedBaseText = memoize(translate, (...args) => JSON.stringify(args));

const customNodeActionsParsers: any = {
  ["n8n-nodes-base.hubspotTrigger"]: (matchedProperty, nodeTypeDescription) => {
    const collection = matchedProperty?.options?.[0];

    return collection?.values[0]?.options?.map((categoryItem): any => ({
      ...getNodeTypeBase(nodeTypeDescription),
      actionKey: categoryItem.value,
      //   displayName: cachedBaseText("nodeCreator.actionsCategory.onEvent", {
      //     interpolate: { event: startCase(categoryItem.name) },
      //   }),
      displayName: "On" + startCase(categoryItem.name),
      description: categoryItem.description ?? "",
      displayOptions: matchedProperty.displayOptions,
      values: { eventsUi: { eventValues: [{ name: categoryItem.value }] } },
    }));
  },
};

function getNodeTypeBase(nodeTypeDescription: any, label?: string) {
  const isTrigger = nodeTypeDescription.group.includes("trigger");
  const category = isTrigger ? "Triggers" : "Actions";
  return {
    name: nodeTypeDescription.name,
    group: nodeTypeDescription.group,
    codex: {
      label: label ?? "",
      categories: [category],
    },
    iconUrl: nodeTypeDescription.iconUrl,
    iconColor: nodeTypeDescription.iconColor,
    outputs: nodeTypeDescription.outputs,
    icon: nodeTypeDescription.icon,
    defaults: nodeTypeDescription.defaults,
  };
}

function operationsCategory(nodeTypeDescription: any): any[] {
  if (
    !!nodeTypeDescription.properties.find(
      (property) => property.name === "resource",
    )
  )
    return [];

  const matchedProperty = nodeTypeDescription.properties.find(
    (property) => property.name?.toLowerCase() === "operation",
  );

  if (!matchedProperty?.options) return [];

  const filteredOutItems = matchedProperty.options.filter(
    (categoryItem: any) => !["*", "", " "].includes(categoryItem.name),
  );

  const items = filteredOutItems.map((item: any) => ({
    ...getNodeTypeBase(nodeTypeDescription),
    actionKey: item.value,
    displayName: item.action ?? startCase(item.name),
    description: item.description ?? "",
    displayOptions: matchedProperty.displayOptions,
    outputConnectionType: item.outputConnectionType,
    values: {
      [matchedProperty.name]:
        matchedProperty.type === "multiOptions" ? [item.value] : item.value,
    },
  }));

  if (items.length === 0) return [];

  return items;
}

function modeCategory(nodeTypeDescription: any): any[] {
  const isAINode =
    nodeTypeDescription.codex?.categories?.includes(AI_SUBCATEGORY);
  if (!isAINode) return [];

  const matchedProperty = nodeTypeDescription.properties.find(
    (property) => property.name?.toLowerCase() === "mode",
  );

  if (!matchedProperty?.options) return [];

  const modeOptions = matchedProperty.options;

  const items = modeOptions.map((item: any) => ({
    ...getNodeTypeBase(nodeTypeDescription),
    actionKey: item.value,
    displayName: item.action ?? startCase(item.name),
    description: item.description ?? "",
    displayOptions: matchedProperty.displayOptions,
    outputConnectionType: item.outputConnectionType,
    values: {
      [matchedProperty.name]: item.value,
    },
  }));

  if (items.length === 0) return [];

  return items;
}

function triggersCategory(nodeTypeDescription: any): any[] {
  const matchingKeys = ["event", "events", "trigger on"];
  const isTrigger = nodeTypeDescription.displayName
    ?.toLowerCase()
    .includes("trigger");
  const matchedProperty = nodeTypeDescription.properties.find((property) =>
    matchingKeys.includes(property.displayName?.toLowerCase()),
  );

  if (!isTrigger) return [];

  if (!matchedProperty?.options) {
    return [
      {
        ...getNodeTypeBase(nodeTypeDescription),
        actionKey: PLACEHOLDER_RECOMMENDED_ACTION_KEY,
        // displayName: cachedBaseText("nodeCreator.actionsCategory.onNewEvent", {
        //   interpolate: {
        //     event: nodeTypeDescription.displayName
        //       .replace("Trigger", "")
        //       .trimEnd(),
        //   },
        // }),
        displayName: `On new ${nodeTypeDescription.displayName
          .replace("Trigger", "")
          .trimEnd()} event`,
        description: "",
      },
    ];
  }

  const filteredOutItems = matchedProperty.options.filter(
    (categoryItem: any) => !["*", "", " "].includes(categoryItem.name),
  );

  const customParsedItem = customNodeActionsParsers[nodeTypeDescription.name]?.(
    matchedProperty,
    nodeTypeDescription,
  );

  const items =
    customParsedItem ??
    filteredOutItems.map((categoryItem: any) => ({
      ...getNodeTypeBase(nodeTypeDescription),
      actionKey: categoryItem.value,
      displayName:
        categoryItem.action ??
        // cachedBaseText("nodeCreator.actionsCategory.onEvent", {
        //   interpolate: { event: formatTriggerActionName(categoryItem.name) },
        // }),
        `On ${formatTriggerActionName(categoryItem.name)}`,
      description: categoryItem.description ?? "",
      displayOptions: matchedProperty.displayOptions,
      values: {
        [matchedProperty.name]:
          matchedProperty.type === "multiOptions"
            ? [categoryItem.value]
            : categoryItem.value,
      },
    }));

  return items;
}

function resourceCategories(nodeTypeDescription: any): any[] {
  const transformedNodes: any[] = [];
  const matchedProperties = nodeTypeDescription.properties.filter(
    (property) => property.displayName?.toLowerCase() === "resource",
  );

  matchedProperties.forEach((property) => {
    (property.options || [])
      .filter((option) => option.value !== CUSTOM_API_CALL_KEY)
      .forEach((resourceOption, _i, options) => {
        const isSingleResource = options.length === 1;

        const operations = nodeTypeDescription.properties.find((operation) => {
          const isOperation = operation.name === "operation";
          const isMatchingResource =
            operation.displayOptions?.show?.resource?.includes(
              resourceOption.value,
            ) ?? isSingleResource;

          const operationVersions =
            operation.displayOptions?.show?.["@version"];
          const nodeTypeVersions = Array.isArray(nodeTypeDescription.version)
            ? nodeTypeDescription.version
            : [nodeTypeDescription.version];

          const isMatchingVersion = operationVersions
            ? operationVersions.some(
                (version) =>
                  typeof version === "number" &&
                  nodeTypeVersions.includes(version),
              )
            : true;

          return isOperation && isMatchingResource && isMatchingVersion;
        });

        if (!operations?.options) return;

        const items: any[] = (operations.options || []).map(
          (operationOption) => {
            const displayName =
              operationOption.action ??
              `${resourceOption.name} ${startCase(operationOption.name)}`;

            const displayOptions = isSingleResource
              ? { show: { resource: [options[0]?.value] } }
              : operations?.displayOptions;

            return {
              ...getNodeTypeBase(
                nodeTypeDescription,
                // `${resourceOption.name} ${cachedBaseText("nodeCreator.actionsCategory.actions")}`,
                `${resourceOption.name} Actions`,
              ),
              actionKey: operationOption.value,
              description: operationOption?.description ?? "",
              displayOptions,
              values: {
                operation:
                  operations?.type === "multiOptions"
                    ? [operationOption.value]
                    : operationOption.value,
              },
              displayName,
              group: ["trigger"],
            };
          },
        );

        transformedNodes.push(...items);
      });
  });

  return transformedNodes;
}
