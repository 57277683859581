import React, { useState } from 'react';
import { axiosGet } from '../../services/axiosService';

interface CommonOAuthButtonProps {
  credentialType: string;
  serviceName: string;
  icon: 'google' | 'salesforce' | 'hubspot' | 'generic';
  buttonText?: string;
  onSuccess?: (credentialId: string, credentialName: string, tokens?: any) => void;
  onError?: (error: string) => void;
  disabled?: boolean;
  className?: string;
}

/**
 * A dynamic OAuth button component that can be used for any OAuth service
 * Handles authorization URL fetching, window opening, and tracking
 */
const CommonOAuthButton: React.FC<CommonOAuthButtonProps> = ({
  credentialType,
  serviceName,
  icon = 'generic',
  buttonText,
  onSuccess,
  onError,
  disabled,
  className,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Get the appropriate icon
  const getIcon = () => {
    switch (icon) {
      case 'google':
        return (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
          </svg>
        );
      case 'salesforce':
        return (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
          </svg>
        );
      case 'hubspot':
        return (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10z"/>
          </svg>
        );
      default:
        return (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
          </svg>
        );
    }
  };
  
  const defaultButtonText = buttonText || `Connect ${serviceName} Account`;
  
  const handleClick = async () => {
    try {
      setLoading(true);
      
      const response = await axiosGet(`/api/oauth2/authorize/${credentialType}`);
      const data = response.data;
      
      if (!data.url) {
        throw new Error('No OAuth URL received');
      }

      console.log('Opening OAuth URL:', data.url);
      
      const width = 600;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      
      const oauthWindow = window.open(
        data.url,
        `oauth-${credentialType}`,
        `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no,location=yes,status=no,scrollbars=1,resizable=1`
      );
      
      if (!oauthWindow || oauthWindow.closed || typeof oauthWindow.closed === 'undefined') {
        throw new Error('Popup was blocked by the browser. Please allow popups for this site.');
      }
      
      const channel = new BroadcastChannel('oauth-callback');
      
      const timeout = setTimeout(() => {
        setLoading(false);
        channel.close();
        setError('Authentication timed out. Please try again.');
      }, 60000);
      
      const checkClosed = setInterval(() => {
        if (oauthWindow && oauthWindow.closed) {
          clearInterval(checkClosed);
          clearTimeout(timeout);
          setLoading(false);
          channel.close();
        }
      }, 500);
      
      channel.onmessage = (event) => {
        const { type, credentialId, error, tokens } = event.data;
        
        if (type === 'oauth-callback') {
          if (error) {
            console.error('OAuth error:', error);
            setError(error);
            if (onError) {
              onError(error);
            }
          } else if (credentialId) {
            setIsConnected(true);
            setShowSuccess(true);
            if (onSuccess) {
              onSuccess(credentialId, data.provider?.displayName || serviceName, tokens);
            }
          }
          
          oauthWindow.close();
          channel.close();
          clearInterval(checkClosed);
          clearTimeout(timeout);
          setLoading(false);
        }
      };
      
    } catch (error) {
      console.error('Error initiating OAuth flow:', error);
      setLoading(false);
      const errorMessage = error instanceof Error ? error.message : 'Failed to initiate authentication';
      setError(errorMessage);
      if (onError) {
        onError(errorMessage);
      }
    }
  };

  return (
    <div className="space-y-4">
      <button
        onClick={handleClick}
        disabled={loading || disabled || isConnected}
        className={`
          flex items-center justify-center px-4 py-2 rounded-md
          ${isConnected 
            ? 'bg-green-600 hover:bg-green-700 text-white' 
            : 'bg-[#FB5F66] hover:bg-[#E3232C] text-white'}
          ${(loading || disabled) && !isConnected ? 'opacity-50 cursor-not-allowed' : ''}
          ${className || ''}
        `}
      >
        <span className="flex items-center">
          {loading ? (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <span className="mr-2">{getIcon()}</span>
          )}
          <span>
            {loading ? 'Connecting...' : isConnected ? `${serviceName} Connected` : defaultButtonText}
          </span>
        </span>
      </button>

      {error && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
            <button 
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setError(null)}
            >
              <span className="sr-only">Close</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      )}

      {showSuccess && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">Successfully connected to {serviceName}!</span>
            <button 
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setShowSuccess(false)}
            >
              <span className="sr-only">Close</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonOAuthButton;
