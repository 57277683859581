import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './index';
import credentialService, { ICredential, ICredentialType } from '../../services/credentialService';

interface CredentialsState {
  types: Record<string, ICredentialType>;
  credentials: Record<string, ICredential>;
  loadingStates: {
    types: boolean;
    credentials: boolean;
    saving: boolean;
    testing: boolean;
  };
  testResult: {
    status: string;
    message: string;
  } | null;
  error: string | null;
}

const initialState: CredentialsState = {
  types: {},
  credentials: {},
  loadingStates: {
    types: false,
    credentials: false,
    saving: false,
    testing: false
  },
  testResult: null,
  error: null
};

// Async thunks
export const fetchCredentialTypes = createAsyncThunk(
  'credentials/fetchTypes',
  async () => {
    const types = await credentialService.getTypes();
    return types.reduce((acc, type) => ({
      ...acc,
      [type.name]: type
    }), {});
  }
);

export const fetchCredentials = createAsyncThunk(
  'credentials/fetchAll',
  async () => {
    const credentials = await credentialService.getAll();
    return credentials.reduce((acc, credential) => ({
      ...acc,
      [credential.id]: credential
    }), {});
  }
);

export const createCredential = createAsyncThunk(
  'credentials/create',
  async (data: Partial<ICredential>) => {
    const credential = await credentialService.create(data);
    return credential;
  }
);

export const updateCredential = createAsyncThunk(
  'credentials/update',
  async ({ id, data }: { id: string; data: Partial<ICredential> }) => {
    const credential = await credentialService.update(id, data);
    return credential;
  }
);

export const deleteCredential = createAsyncThunk(
  'credentials/delete',
  async (id: string) => {
    await credentialService.delete(id);
    return id;
  }
);

export const testCredential = createAsyncThunk(
  'credentials/test',
  async ({ type, data }: { type: string; data: Record<string, any> }) => {
    const result = await credentialService.test(type, data);
    return result;
  }
);

// Slice
const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    clearTestResult: (state) => {
      state.testResult = null;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch types
      .addCase(fetchCredentialTypes.pending, (state) => {
        state.loadingStates.types = true;
        state.error = null;
      })
      .addCase(fetchCredentialTypes.fulfilled, (state, action) => {
        state.types = action.payload;
        state.loadingStates.types = false;
      })
      .addCase(fetchCredentialTypes.rejected, (state, action) => {
        state.loadingStates.types = false;
        state.error = action.error.message || 'Failed to fetch credential types';
      })

      // Fetch credentials
      .addCase(fetchCredentials.pending, (state) => {
        state.loadingStates.credentials = true;
        state.error = null;
      })
      .addCase(fetchCredentials.fulfilled, (state, action) => {
        state.credentials = action.payload;
        state.loadingStates.credentials = false;
      })
      .addCase(fetchCredentials.rejected, (state, action) => {
        state.loadingStates.credentials = false;
        state.error = action.error.message || 'Failed to fetch credentials';
      })

      // Create credential
      .addCase(createCredential.pending, (state) => {
        state.loadingStates.saving = true;
        state.error = null;
      })
      .addCase(createCredential.fulfilled, (state, action) => {
        state.credentials[action.payload.id] = action.payload;
        state.loadingStates.saving = false;
      })
      .addCase(createCredential.rejected, (state, action) => {
        state.loadingStates.saving = false;
        state.error = action.error.message || 'Failed to create credential';
      })

      // Update credential
      .addCase(updateCredential.pending, (state) => {
        state.loadingStates.saving = true;
        state.error = null;
      })
      .addCase(updateCredential.fulfilled, (state, action) => {
        state.credentials[action.payload.id] = action.payload;
        state.loadingStates.saving = false;
      })
      .addCase(updateCredential.rejected, (state, action) => {
        state.loadingStates.saving = false;
        state.error = action.error.message || 'Failed to update credential';
      })

      // Delete credential
      .addCase(deleteCredential.fulfilled, (state, action) => {
        delete state.credentials[action.payload];
      })
      .addCase(deleteCredential.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete credential';
      })

      // Test credential
      .addCase(testCredential.pending, (state) => {
        state.loadingStates.testing = true;
        state.testResult = null;
        state.error = null;
      })
      .addCase(testCredential.fulfilled, (state, action) => {
        state.testResult = action.payload;
        state.loadingStates.testing = false;
      })
      .addCase(testCredential.rejected, (state, action) => {
        state.loadingStates.testing = false;
        state.error = action.error.message || 'Failed to test credential';
      });
  }
});

// Actions
export const { clearTestResult, clearError } = credentialsSlice.actions;

// Selectors
export const selectCredentialTypes = (state: RootState) => state.credentials.types;
export const selectCredentials = (state: RootState) => state.credentials.credentials;
export const selectLoadingStates = (state: RootState) => state.credentials.loadingStates;
export const selectTestResult = (state: RootState) => state.credentials.testResult;
export const selectError = (state: RootState) => state.credentials.error;

export default credentialsSlice.reducer; 