import { CUSTOM_API_CALL_KEY } from "../constants";
import {
  DisplayCondition,
  IDataObject,
  INode,
  INodeCredentialDescription,
  INodeParameters,
  INodeProperties,
  NodeParameterValue,
  ResolveParameterOptions,
} from "./interfaces";
import { get, isEqual, isObject, isString } from "lodash";

const getPropertyValues = (
  nodeValues: INodeParameters,
  propertyName: string,
  node: Pick<INode, "typeVersion"> | null,
  nodeValuesRoot: INodeParameters,
) => {
  let value;
  if (propertyName.charAt(0) === "/") {
    // Get the value from the root of the node
    value = get(nodeValuesRoot, propertyName.slice(1));
  } else if (propertyName === "@version") {
    value = node?.typeVersion || 0;
  } else {
    // Get the value from current level
    value = get(nodeValues, propertyName);
  }

  if (value && typeof value === "object" && "__rl" in value && value.__rl) {
    value = value.value;
  }

  if (!Array.isArray(value)) {
    return [value as NodeParameterValue];
  } else {
    return value as NodeParameterValue[];
  }
};

const checkConditions = (
  conditions: Array<NodeParameterValue | DisplayCondition>,
  actualValues: NodeParameterValue[],
) => {
  return conditions.some((condition) => {
    if (
      condition &&
      typeof condition === "object" &&
      condition._cnd &&
      Object.keys(condition).length === 1
    ) {
      const [key, targetValue] = Object.entries(condition._cnd)[0];

      return actualValues.every((propertyValue) => {
        if (key === "eq") {
          return isEqual(propertyValue, targetValue);
        }
        if (key === "not") {
          return !isEqual(propertyValue, targetValue);
        }
        if (key === "gte") {
          return (propertyValue as number) >= targetValue;
        }
        if (key === "lte") {
          return (propertyValue as number) <= targetValue;
        }
        if (key === "gt") {
          return (propertyValue as number) > targetValue;
        }
        if (key === "lt") {
          return (propertyValue as number) < targetValue;
        }
        if (key === "between") {
          const { from, to } = targetValue as { from: number; to: number };
          return (
            (propertyValue as number) >= from && (propertyValue as number) <= to
          );
        }
        if (key === "includes") {
          return (propertyValue as string).includes(targetValue);
        }
        if (key === "startsWith") {
          return (propertyValue as string).startsWith(targetValue);
        }
        if (key === "endsWith") {
          return (propertyValue as string).endsWith(targetValue);
        }
        if (key === "regex") {
          return new RegExp(targetValue as string).test(
            propertyValue as string,
          );
        }
        if (key === "exists") {
          return (
            propertyValue !== null &&
            propertyValue !== undefined &&
            propertyValue !== ""
          );
        }
        return false;
      });
    }

    return actualValues.includes(condition as NodeParameterValue);
  });
};

/**
 * Returns if the given parameter should be displayed or not considering the path
 * to the properties
 *
 * @param {INodeParameters} nodeValues The data on the node which decides if the parameter
 *                                    should be displayed
 * @param {(INodeProperties | INodeCredentialDescription)} parameter The parameter to check if it should be displayed
 * @param {string} path The path to the property
 */
export function displayParameterPath(
  nodeValues: INodeParameters,
  parameter: INodeProperties | INodeCredentialDescription,
  path: string,
  node: Pick<INode, "typeVersion"> | null,
  displayKey: "displayOptions" | "disabledOptions" = "displayOptions",
) {
  let resolvedNodeValues = nodeValues;
  if (path !== "") {
    resolvedNodeValues = get(nodeValues, path) as INodeParameters;
  }

  // Get the root parameter data
  let nodeValuesRoot = nodeValues;
  if (path && path.split(".").indexOf("parameters") === 0) {
    nodeValuesRoot = get(nodeValues, "parameters") as INodeParameters;
  }

  return displayParameter(
    resolvedNodeValues,
    parameter,
    node,
    nodeValuesRoot,
    displayKey,
  );
}

/**
 * Returns if the parameter should be displayed or not
 *
 * @param {INodeParameters} nodeValues The data on the node which decides if the parameter
 *                                    should be displayed
 * @param {(INodeProperties | INodeCredentialDescription)} parameter The parameter to check if it should be displayed
 * @param {INodeParameters} [nodeValuesRoot] The root node-parameter-data
 */
export function displayParameter(
  nodeValues: INodeParameters,
  parameter: INodeProperties | INodeCredentialDescription,
  node: Pick<INode, "typeVersion"> | null, // Allow null as it does also get used by credentials and they do not have versioning yet
  nodeValuesRoot?: INodeParameters,
  displayKey: "displayOptions" | "disabledOptions" = "displayOptions",
) {
  if (!parameter[displayKey]) {
    return true;
  }

  const { show, hide } = parameter[displayKey];

  nodeValuesRoot = nodeValuesRoot || nodeValues;

  if (show) {
    // All the defined rules have to match to display parameter
    for (const propertyName of Object.keys(show)) {
      const values = getPropertyValues(
        nodeValues,
        propertyName,
        node,
        nodeValuesRoot,
      );

      if (values.some((v) => typeof v === "string" && v.charAt(0) === "=")) {
        return true;
      }

      if (
        values.length === 0 ||
        !checkConditions(show[propertyName]!, values)
      ) {
        return false;
      }
    }
  }

  if (hide) {
    // Any of the defined hide rules have to match to hide the parameter
    for (const propertyName of Object.keys(hide)) {
      const values = getPropertyValues(
        nodeValues,
        propertyName,
        node,
        nodeValuesRoot,
      );

      if (values.length !== 0 && checkConditions(hide[propertyName]!, values)) {
        return false;
      }
    }
  }

  return true;
}

export function isCustomApiCallSelected(nodeValues: INodeParameters): boolean {
  const { properties: parameters } = nodeValues;

  if (!isObject(parameters)) return false;

  if ("resource" in parameters || "operation" in parameters) {
    const { resource, operation } = parameters;

    return (
      (isString(resource) && resource.includes(CUSTOM_API_CALL_KEY)) ||
      (isString(operation) && operation.includes(CUSTOM_API_CALL_KEY))
    );
  }

  return false;
}

// function resolveExpression(
//   expression: string,
//   siblingParameters: INodeParameters = {},
//   opts: ResolveParameterOptions & { c?: number } = {},
//   stringifyObject = true,
// ) {
//   const parameters = {
//     __xxxxxxx__: expression,
//     ...siblingParameters,
//   };
//   const returnData: IDataObject | null = resolveParameter(parameters, opts);
//   if (!returnData) {
//     return null;
//   }

//   const obj = returnData.__xxxxxxx__;
//   if (typeof obj === 'object' && stringifyObject) {
//     const proxy = obj as { isProxy: boolean; toJSON?: () => unknown } | null;
//     if (proxy?.isProxy && proxy.toJSON) return JSON.stringify(proxy.toJSON());
//     const workflow = getCurrentWorkflow();
//     return workflow.expression.convertObjectValueToString(obj as object);
//   }
//   return obj;
// }
