// import { KEEP_AUTH_IN_NDV_FOR_NODES } from "../constants";
import { get } from "lodash";
import {
  INodeCredentialDescription,
  INodeParameters,
  INodeProperties,
} from "../Workflow/interfaces";
import {
  displayParameterPath,
  isCustomApiCallSelected,
} from "../Workflow/nodeHelper";

// call these methods from the manager class
export const getNodeWithProperties = (
  allGroupedNeurodes: any,
  nodeTypeName: string,
  version?: string,
) => {
  // to be handled once crediential manager is setup
  // if (utils.isCredentialOnlyNodeType(nodeTypeName)) {
  //     return getCredentialOnlyNodeType(nodeTypeName, version);
  // }

  const selectedNode = allGroupedNeurodes[nodeTypeName];
  if (!selectedNode) return null;

  const availableVersions = Object.keys(selectedNode).map(Number);
  const nodeType = selectedNode[version ?? Math.max(...availableVersions)]; // using latest v if not mentioned
  return nodeType ?? null;
};

export const getFilteredParameters = (parameters, activeNode) => {
  const filteredParameters = parameters.filter((parameter: INodeProperties) =>
    displayNodeParameter(parameter, activeNode, "parameters"),
  );
  // we will use these conditions once we actually add these nodes

  // if (activeNode && activeNode.name === FORM_TRIGGER_NODE_TYPE) {
  // 	return updateFormTriggerParameters(parameters, activeNode.name);
  // }
  // if (
  // 	activeNode &&
  // 	activeNode.name === WAIT_NODE_TYPE &&
  // 	activeNode.parameters.resume === 'form'
  // ) {
  // 	return updateWaitParameters(parameters, activeNode.name);
  // }

  return filteredParameters;
};

function displayNodeParameter(
  parameter: INodeProperties,
  node: any, // current node
  path: any,
  displayKey: "displayOptions" | "disabledOptions" = "displayOptions",
): boolean {
  if (parameter.type === "hidden") {
    return false;
  }

  if (
    isCustomApiCallSelected(node) &&
    mustHideDuringCustomApiCall(parameter, node)
  ) {
    return false;
  }

  // Hide authentication related fields since it will now be part of credentials modal (but we will not, yet)
  // if (
  //   !KEEP_AUTH_IN_NDV_FOR_NODES.includes(node?.type || "") &&
  //   mainNodeAuthField.value &&
  //   (parameter.name === mainNodeAuthField.value?.name ||
  //     shouldHideAuthRelatedParameter(parameter))
  // ) {
  //   return false;
  // }

  if (parameter[displayKey] === undefined) {
    // If it is not defined no need to do a proper check
    return true;
  }

  const nodeValues: any = {};
  // let rawValues = props.nodeValues;
  let rawValues = node; // will contain user filled values
  if (path) {
    rawValues = get(node, path) as INodeParameters;
  }

  if (!rawValues) {
    return false;
  }
  // Resolve expressions (currently we do not support this)

  const resolveKeys = Object.keys(rawValues);
  let key: string;
  let parameterGotResolved = false;
  // let i = 0;
  // do {
  //   key = resolveKeys.shift() as string;
  //   const value = rawValues[key];
  //   if (typeof value === "string" && value?.charAt(0) === "=") {
  //     // Contains an expression that
  //     if (
  //       value.includes("$parameter") &&
  //       resolveKeys.some((parameterName) => value.includes(parameterName))
  //     ) {
  //       // Contains probably an expression of a missing parameter so skip
  //       resolveKeys.push(key);
  //       continue;
  //     } else {
  //       // Contains probably no expression with a missing parameter so resolve
  //       try {
  //         nodeValues[key] = workflowHelpers.resolveExpression(
  //           value,
  //           nodeValues,
  //         );
  //       } catch (e) {
  //         // If expression is invalid ignore
  //         nodeValues[key] = "";
  //       }
  //       parameterGotResolved = true;
  //     }
  //   } else {
  //     // Does not contain an expression, add directly
  //     nodeValues[key] = rawValues[key];
  //   }
  //   // TODO: Think about how to calculate this best
  //   if (i++ > 50) {
  //     // Make sure we do not get caught
  //     break;
  //   }
  // } while (resolveKeys.length !== 0);

  do {
    key = resolveKeys.shift() as string;
    nodeValues[key] = rawValues[key];
  } while (resolveKeys.length !== 0);

  // if (parameterGotResolved) {
  //   if (path) {
  //     rawValues = structuredClone(props.nodeValues);
  //     set(rawValues, path, nodeValues);
  //     return nodeHelpers.displayParameter(
  //       rawValues,
  //       parameter,
  //       path,
  //       node,
  //       displayKey,
  //     );
  //   } else {
  //     return nodeHelpers.displayParameter(
  //       nodeValues,
  //       parameter,
  //       "",
  //       node,
  //       displayKey,
  //     );
  //   }
  // }

  return displayParameter(nodeValues, parameter, "", node, displayKey);
}

function displayParameter(
  nodeValues: INodeParameters,
  parameter: INodeProperties | INodeCredentialDescription,
  path: string,
  node: any | null, // INodeUi
  displayKey: "displayOptions" | "disabledOptions" = "displayOptions",
) {
  return displayParameterPath(nodeValues, parameter, path, node, displayKey);
}

function mustHideDuringCustomApiCall(
  parameter: INodeProperties,
  nodeValues: INodeParameters,
): boolean {
  if (parameter?.displayOptions?.hide) return true;

  const MUST_REMAIN_VISIBLE = [
    "authentication",
    "resource",
    "operation",
    ...Object.keys(nodeValues),
  ];

  return !MUST_REMAIN_VISIBLE.includes(parameter.name);
}
