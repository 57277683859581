import { ICredentialType, ICredentialProperty, ICredentialTypeDescription } from '../types/credentials';

/**
 * Normalizes a credential type by extracting properties from either direct properties or nested description
 * This handles both n8n credential formats:
 * 1. Properties directly on the credential type
 * 2. Properties nested in a description object
 * 3. Properties inherited from parent credential types
 */
export const normalizeCredentialType = (credentialType: ICredentialType): ICredentialType => {
  if (!credentialType) return credentialType;

  // If the credential type has a description object with properties, use those
  if (credentialType.description && typeof credentialType.description === 'object') {
    const descObj = credentialType.description as ICredentialTypeDescription;
    return {
      name: credentialType.name,
      displayName: credentialType.displayName,
      description: descObj.description || '',
      icon: credentialType.icon,
      documentationUrl: credentialType.documentationUrl,
      properties: credentialType.properties || [],
      authenticate: credentialType.authenticate,
      extends: credentialType.extends,
      hidden: credentialType.hidden || false,
    };
  }
  
  // Otherwise, use the properties directly on the credential type
  return {
    ...credentialType,
    hidden: credentialType.hidden || false,
  };
};

/**
 * Gets credential properties from a credential type, handling inheritance
 */
export const getCredentialProperties = (
  credentialType: ICredentialType,
  credentialTypes: Map<string, ICredentialType>,
): ICredentialProperty[] => {
  if (!credentialType) return [];

  const properties: ICredentialProperty[] = [];
  
  // Handle inheritance
  if (credentialType.extends) {
    const parentTypes = Array.isArray(credentialType.extends) 
      ? credentialType.extends 
      : [credentialType.extends];
    
    for (const parentType of parentTypes) {
      const parentCredType = credentialTypes.get(parentType);
      if (parentCredType) {
        const parentProps = getCredentialProperties(parentCredType, credentialTypes);
        mergeProperties(properties, parentProps);
      }
    }
  }

  // Current type's properties take precedence
  mergeProperties(properties, credentialType.properties || []);
  
  return properties;
};

/**
 * Merges credential properties, with target properties taking precedence
 */
export const mergeProperties = (target: ICredentialProperty[], source: ICredentialProperty[]) => {
  for (const prop of source) {
    const existingIndex = target.findIndex(p => p.name === prop.name);
    if (existingIndex >= 0) {
      // If property exists, merge it
      target[existingIndex] = {
        ...target[existingIndex],
        ...prop,
        typeOptions: {
          ...target[existingIndex].typeOptions,
          ...prop.typeOptions,
        },
        displayOptions: {
          ...target[existingIndex].displayOptions,
          ...prop.displayOptions,
        },
      };
    } else {
      // If property doesn't exist, add it
      target.push({ ...prop });
    }
  }
};

/**
 * Checks if a property should be displayed based on its display options
 */
export const shouldDisplayProperty = (
  property: ICredentialProperty,
  credentialData: Record<string, any>,
): boolean => {
  if (!property.displayOptions) return true;

  // Check show conditions
  if (property.displayOptions.show) {
    const showResult = Object.entries(property.displayOptions.show).every(([field, values]) => {
      const currentValue = credentialData[field];
      return values.includes(currentValue);
    });
    if (!showResult) return false;
  }

  // Check hide conditions
  if (property.displayOptions.hide) {
    const hideResult = Object.entries(property.displayOptions.hide).some(([field, values]) => {
      const currentValue = credentialData[field];
      return values.includes(currentValue);
    });
    if (hideResult) return false;
  }

  return true;
};

/**
 * Validates credential data against its type definition
 */
export const validateCredentialData = (
  credentialType: ICredentialType,
  credentialData: Record<string, any>,
  credentialTypes: Map<string, ICredentialType>,
): { isValid: boolean; errors: Record<string, string> } => {
  const properties = getCredentialProperties(credentialType, credentialTypes);
  const errors: Record<string, string> = {};
  
  for (const property of properties) {
    if (property.required && !credentialData[property.name]) {
      errors[property.name] = `${property.displayName} is required`;
    }
  }
  
  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

/**
 * Checks if a credential type is OAuth based
 */
export const isOAuthCredentialType = (credentialType: ICredentialType): boolean => {
  // Check if the name indicates it's an OAuth credential type
  if (credentialType.name && (
    credentialType.name.toLowerCase().includes('oauth') ||
    credentialType.name.toLowerCase().includes('gmail') ||
    credentialType.name.toLowerCase().includes('google')
  )) {
    console.log(`Credential type ${credentialType.name} detected as OAuth based on name`);
    return true;
  }
  
  // Check direct authenticate property
  if (credentialType.authenticate && credentialType.authenticate.type === 'oauth2') {
    console.log(`Credential type ${credentialType.name} detected as OAuth based on authenticate property`);
    return true;
  }
  
  // Check authenticate property in description
  if (credentialType.description && 
      typeof credentialType.description === 'object') {
    const descObj = credentialType.description as ICredentialTypeDescription;
    if (descObj.authenticate && descObj.authenticate.type === 'oauth2') {
      console.log(`Credential type ${credentialType.name} detected as OAuth based on description.authenticate property`);
      return true;
    }
  }
  
  // Check if it extends OAuth2 types
  if (credentialType.extends) {
    const extendsArray = Array.isArray(credentialType.extends) 
      ? credentialType.extends 
      : [credentialType.extends];
    
    if (extendsArray.some(ext => 
      ext.toLowerCase().includes('oauth') || 
      ext.toLowerCase().includes('gmail') || 
      ext.toLowerCase().includes('google')
    )) {
      console.log(`Credential type ${credentialType.name} detected as OAuth based on extends property: ${extendsArray.join(', ')}`);
      return true;
    }
  }
  
  // Check for OAuth-related properties
  const properties = getCredentialProperties(credentialType, new Map());
  const hasOAuthProperties = properties.some(prop => 
    prop.name === 'authUrl' || 
    prop.name === 'tokenUrl' || 
    prop.name === 'accessToken' || 
    prop.name === 'refreshToken' ||
    prop.name === 'clientId' ||
    prop.name === 'clientSecret'
  );
  
  if (hasOAuthProperties) {
    console.log(`Credential type ${credentialType.name} detected as OAuth based on OAuth-related properties`);
    return true;
  }
  
  return false;
};

/**
 * Gets the OAuth properties from a credential type
 */
export const getOAuthProperties = (credentialType: ICredentialType): any => {
  // Check direct authenticate property
  if (credentialType.authenticate && credentialType.authenticate.type === 'oauth2') {
    return credentialType.authenticate.properties;
  }
  
  // Check authenticate property in description
  if (credentialType.description && 
      typeof credentialType.description === 'object') {
    const descObj = credentialType.description as ICredentialTypeDescription;
    if (descObj.authenticate && descObj.authenticate.type === 'oauth2') {
      return descObj.authenticate.properties;
    }
  }
  
  return null;
};

/**
 * Checks if a credential type extends another credential type
 */
export const extendsCredentialType = (credentialType: ICredentialType, parentName: string): boolean => {
  // Check direct extends property
  if (credentialType.extends && Array.isArray(credentialType.extends)) {
    return credentialType.extends.includes(parentName);
  }
  
  // Check extends property in description
  if (credentialType.description && 
      typeof credentialType.description === 'object') {
    const descObj = credentialType.description as ICredentialTypeDescription;
    if (descObj.extends && Array.isArray(descObj.extends)) {
      return descObj.extends.includes(parentName);
    }
  }
  
  return false;
};

/**
 * Gets the parent credential types that this credential type extends
 */
export const getParentCredentialTypes = (credentialType: ICredentialType): string[] => {
  // Check direct extends property
  if (credentialType.extends && Array.isArray(credentialType.extends)) {
    return credentialType.extends;
  }
  
  // Check extends property in description
  if (credentialType.description && 
      typeof credentialType.description === 'object') {
    const descObj = credentialType.description as ICredentialTypeDescription;
    if (descObj.extends && Array.isArray(descObj.extends)) {
      return descObj.extends;
    }
  }
  
  return [];
};
