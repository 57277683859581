import { createContext, useReducer } from "react";
import { produce } from "immer";
import type { Url } from "./types";
import { type ConfigId } from "@/constants/ConfigData";

interface State {
  botData: Record<string, any>;
  openConfigModalId: ConfigId | "";
  crawlData: Map<string, Url>;
  extraData: Map<string, Url>;
  url: string;
  tab: number;
  callGenaiFnc?: string[];
  textAreaContent: null | string;
  textAreaContentPrev: null | string;
}

type Action =
  | { type: "set-botData"; botData: Record<string, any> }
  | { type: "set-updatedAt"; updatedAt: string }
  | { type: "set-openConfigModalId"; configId: ConfigId | "" }
  | { type: "tab-set"; tab: number }
  | { type: "url-set"; url: string }
  | {
      type: "crawl-init";
      crawlData: Map<string, Url>;
    }
  | { type: "crawl-add"; crawlData: Map<string, Url> }
  | { type: "crawl-remove"; ids: string[] }
  | { type: "crawl-toggle"; id: string }
  | { type: "crawl-set-selected"; range: number; state: boolean }
  | { type: "extra-add"; extraData: Map<string, Url>; selected: boolean }
  | { type: "extra-select"; id: string }
  | { type: "extra-delete"; id: string }
  | { type: "extra-delete-all" }
  | { type: "data-sources-set"; dataSources: Record<string, any> }
  | { type: "data-sources-update"; dataSources: Record<string, any> }
  | { type: "toggle-genai-function"; functionNames: string[] }
  | { type: "crawl-delete"; id: string }
  | { type: "set-aiSource"; dataKey: string; dataSet: any[] }
  | { type: "add-aiSource"; dataKey: string; dataSet: any[] }
  | { type: "set-textAreaContent"; textAreaContent: string }
  | { type: "set-textAreaContentPrev"; textAreaContent: string };

type Context = [State, React.Dispatch<Action>];

const initialState: State = {
  botData: {},
  openConfigModalId: "",
  url: "",
  // tab: window.screen.width <= 1279 ? 2 : 1,
  tab: 1,
  crawlData: new Map(),
  extraData: new Map(),
  textAreaContent: null,
  textAreaContentPrev: null,
};

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "set-botData": {
      draft.botData = action.botData;
      break;
    }
    case "set-updatedAt": {
      draft.botData.updatedAt = action.updatedAt;
      break;
    }
    case "set-openConfigModalId": {
      draft.openConfigModalId = action.configId;
      break;
    }
    case "tab-set": {
      draft.tab = action.tab;
      break;
    }
    case "url-set": {
      draft.url = action.url;
      break;
    }
    case "crawl-init": {
      draft.crawlData = action.crawlData;
      break;
    }
    case "crawl-add": {
      action.crawlData.forEach((value, key) => {
        draft.crawlData.set(key, value);
      });
      break;
    }
    case "crawl-remove": {
      for (const id of action.ids) {
        draft.crawlData.delete(id);
      }
      break;
    }
    case "crawl-toggle": {
      const value = draft.crawlData.get(action.id);
      if (!value) break;
      draft.crawlData.set(action.id, { ...value, selected: !value.selected });
      break;
    }
    case "crawl-set-selected": {
      let counter = 0;
      draft.crawlData.forEach((value, key) => {
        if (action.range === -1 || counter < action.range) {
          draft.crawlData.set(key, { ...value, selected: action.state });
        } else {
          draft.crawlData.set(key, { ...value, selected: !action.state });
        }
        counter++;
      });
      break;
    }
    case "extra-add": {
      const newEntries = new Map();
      action.extraData.forEach((value, key) => {
        newEntries.set(key, { ...value });
      });
      draft.crawlData.forEach((value, key) => {
        if (!newEntries.has(key)) {
          newEntries.set(key, value);
        }
      });
      draft.crawlData = newEntries;
      break;
    }
    case "extra-select": {
      const value = draft.extraData.get(action.id);
      if (!value) break;
      draft.extraData.set(action.id, { ...value, selected: true });
      break;
    }
    case "extra-delete": {
      draft.extraData.delete(action.id);
      break;
    }
    case "extra-delete-all": {
      draft.extraData = new Map();
      break;
    }
    case "data-sources-set": {
      draft.botData.dataSources = action.dataSources;
      break;
    }
    case "data-sources-update": {
      draft.botData.dataSources = {
        ...draft.botData.dataSources,
        ...action.dataSources,
      };
      break;
    }
    case "set-aiSource": {
      draft.botData.aiSource[action.dataKey] = action.dataSet;
      break;
    }
    case "add-aiSource": {
      draft.botData.aiSource[action.dataKey] = [
        ...action.dataSet,
        ...draft.botData.aiSource[action.dataKey],
      ];
      break;
    }
    case "toggle-genai-function": {
      draft.callGenaiFnc = action.functionNames;
      break;
    }
    case "crawl-delete": {
      draft.crawlData.delete(action.id);
      break;
    }
    case "set-textAreaContent": {
      draft.textAreaContent = action.textAreaContent;
      break;
    }
    case "set-textAreaContentPrev": {
      draft.textAreaContentPrev = action.textAreaContent;
      break;
    }
  }
});

export const BotContext = createContext<Context>([initialState, () => {}]);

export default function BotContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [store, storeDispatch] = useReducer(reducer, initialState);

  return (
    <BotContext.Provider value={[store, storeDispatch]}>
      {children}
    </BotContext.Provider>
  );
}
