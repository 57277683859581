import { configureStore } from '@reduxjs/toolkit';
import credentialsReducer from './credentials.store';
import userReducer from '../../redux/User';
import botReducer from '../../redux/Bot';
import appReducer from '../../redux/IntegrationApps';
import environment from '../../constants/Environment';

const environmentData = environment();

function loadFromLocalStorage() {
  try {
    const data = JSON.parse(
      localStorage.getItem(
        `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
      ) ?? "{}",
    );
    return data;
  } catch {
    return {};
  }
}

export const store = configureStore<{
  credentials: ReturnType<typeof credentialsReducer>;
  user: ReturnType<typeof userReducer>;
  bot: ReturnType<typeof botReducer>;
  apps: ReturnType<typeof appReducer>;
}>({
  reducer: {
    credentials: credentialsReducer,
    user: userReducer,
    bot: botReducer,
    apps: appReducer,
  },
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => {
  const state = store.getState();
  const data = structuredClone(state);
  data.user.textToWorkflowPrompt = "";

  if (data.user.isLoggedIn) {
    localStorage.setItem(
      `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
      JSON.stringify(data),
    );
  } else {
    localStorage.removeItem(
      `${environmentData.isAI ? "state_cbb" : "state_omni"}`,
    );
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch; 