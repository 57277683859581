import axios from "axios";
import environment from "@/constants/Environment";
import { INode } from "../Workflow/interfaces";
let cachedData: INode[] | null = null;

const getNeurodeNodes = async (): Promise<INode[]> => {
  try {
    if (cachedData) return cachedData;

    const { data } = await axios.get(
      `${environment().SERVER_API_URL}/neurodes/dist/types/nodes.json`,
      // {
      //   headers: {
      //     "Cache-Control": "max-age=31536000, public",
      //     Pragma: "cache",
      //     Expires: new Date(Date.now() + 31536000 * 1000).toUTCString(),
      //   },
      // },
    );

    const processedData: INode[] = [];

    for (const item of data) {
      if (item.name === "emailSend") continue; // temp hack, will add back once emailSend is fixed
      processedData.push({
        ...item,
        name: "neurode." + item.name,
      });
    }

    cachedData = processedData;
    return processedData;
  } catch (error) {
    console.error("Error fetching neurodes", error);
    return [];
  }
};

// const getNeurodeNodes = (): INode[] => {
//   const processedData: INode[] = [];
//   if (cachedData) return cachedData;

//   for (const item of Neurodes as any[]) {
//     if (item.name === "emailSend") continue; // temp hack, will add back once emailSend is fixed
//     processedData.push({
//       ...item,
//       name: "neurode." + item.name,
//     });
//   }
//   cachedData = processedData;
//   return processedData;
// };

export default getNeurodeNodes;
