import { useEffect, useReducer } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import useProgress from "@/utils/useProgress";
import ShortSideNav from "./shortSideNav";
import SideNav from "./sideNav";
import OmniSideNav from "./OmniSideNav";
import environment from "../../constants/Environment";
import DisableLayout from "@/layouts/DisableLayout";
import { useRef, useState } from "react";
import BooleanModal from "../builder/BooleanModal";
import DisabledOmniSideNav from "./DisabledOmniSideNav";
import DisabledCbbSideNav from "./DisabledCbbSideNav";
import { useDispatch, useSelector } from "react-redux";
import { State } from "@/redux/Types";
import { userSlice } from "@/redux/User";
import ShowModal from "@/components/layoutElements/modals/showModal";
import ToastPopUp from "@/components/layoutElements/toasts/toast";
import { setCookie } from "@/services/cookieService";

type routeType =
  | "/dashboard"
  | "/integration"
  | "/analytics"
  | "/chats"
  | "/audience"
  | "/settings";

export default function RootLayout() {
  const dispatch = useDispatch();
  const fetchProgress = useProgress();
  const [fReload, forceReload] = useReducer((state) => !state, false);
  const route = useRef<routeType>("/dashboard");
  const [showBooleanModal, setShowBooleanModal] = useState<boolean>(false);
  const router = useNavigate();
  const [searchParams, _] = useSearchParams();
  const compDetails = useSelector((state: State) => state.user.companyDetails);
  const showModal = useSelector((state: State) =>
    state.user?.infoModal?.showModal ? state.user.infoModal.showModal : null,
  );
  const modalType = useSelector((state: State) =>
    state.user?.infoModal?.modalType ? state.user.infoModal.modalType : null,
  );
  const modalTitle = useSelector((state: State) =>
    state.user?.infoModal?.modalTitle ? state.user.infoModal.modalTitle : null,
  );
  const modalMessage = useSelector((state: State) =>
    state.user?.infoModal?.modalMessage
      ? state.user.infoModal.modalMessage
      : null,
  );
  const modalButtonText = useSelector((state: State) =>
    state.user?.infoModal?.modalButtonText
      ? state.user.infoModal.modalButtonText
      : null,
  );
  const showModalButton = useSelector((state: State) =>
    state.user?.infoModal?.showModalButton === false ? false : true,
  );
  const calledFromAgent = useSelector((state: State) =>
    !state.user?.infoModal?.calledFromAgent ? false : true,
  );
  const showToastPopUp = useSelector((state: State) =>
    state.user?.toast?.show ? state.user.toast.show : null,
  );
  const toastType = useSelector((state: State) =>
    state.user?.toast?.toastType ? state.user.toast.toastType : null,
  );
  const toastMessage = useSelector((state: State) =>
    state.user?.toast?.message ? state.user.toast.message : null,
  );
  const toastDuration = useSelector((state: State) =>
    state.user?.toast?.duration ? state.user.toast.duration : null,
  );
  const isLoggedIn = useSelector((state: State) => state.user.isLoggedIn);
  const trainingPending = useSelector(
    (state: State) => state.user.trainingPending,
  );
  const trainingProgress = useSelector(
    (state: State) => state.user.trainingProgress,
  );

  const userId = useSelector((state: State) => state.user.userId);
  const addClass = useSelector((state: State) => state.user.addClass);

  useEffect(() => {
    dispatch(userSlice.actions.setSelectedBot(""));
    dispatch(userSlice.actions.setSelectedChat(""));
  }, [userId]);

  const handleClickForDropdown = (e) => {
    if (!e.target.closest(".user_details")) {
      if (!$(".user_profile_dropdown").hasClass("none"))
        $(".user_profile_dropdown").addClass("none");
    }
  };

  const handleSetModal = () => {
    dispatch(
      userSlice.actions.setInfoModal({
        showModal: false,
        modalType: "",
        modalTitle: "",
        modalMessage: "",
      }),
    );
  };

  const handleSetToast = () => {
    dispatch(
      userSlice.actions.setToastPopUp({
        show: false,
        toastType: "",
        message: "",
      }),
    );
  };

  useEffect(() => {
    dispatch(userSlice.actions.setDebug(searchParams.get("debug") === "true"));
    dispatch(userSlice.actions.setBeta(searchParams.get("beta") === "true"));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setCookie(
        "loggedIn",
        "true",
        1,
        environment()?.PAGE_DOMAIN,
        "/",
      );
      dispatch(
        userSlice.actions.setTrainData({
          pending: false,
          process: 100,
          botId: "",
          type: "webpage",
        }),
      );
      fetchProgress({ onFinish: forceReload });
    }
  }, [isLoggedIn]);

  // const setRoute = (routeInput: routeType = "/dashboard") => router(routeInput);
  const setRoute = (routeInput: routeType = "/dashboard") => {
    route.current = routeInput;
    if (window.location.pathname.includes("/builder"))
      setShowBooleanModal(true);
    else router(route.current);
  };

  const handleRoute = (confirm: boolean) => {
    if (confirm) router(route.current);
    setShowBooleanModal(false);
  };
  const sideNav = environment().isAI ? (
    <SideNav />
  ) : (
    !compDetails?.fromMu ? <OmniSideNav setRoute={setRoute} /> : null
  );
  

  return (
    <div
      className={`main-app ${addClass}`}
      onClick={(e) => {
        handleClickForDropdown(e);
      }}
    >
      <div className="iconNav-bar">
        {window.location.pathname === "/dashboard" ||
        window.location.pathname === "/dashboard/" ? (
          sideNav
        ) : window.location.pathname === "/chats" ||
          window.location.pathname === "/chats/" ? (
          environment().isAI ? (
            <ShortSideNav setRoute={setRoute} />
          ) : (
            sideNav
          )
        ) : window.location.pathname === "/analytics" ||
          window.location.pathname === "/analytics/" ? (
          sideNav
        ) : window.location.pathname === "/audience" ||
          window.location.pathname === "/audience/" ? (
          sideNav
        ) : /^\/builder\/.*/.test(window.location.pathname) ? (
          environment().isAI ? (
            <ShortSideNav setRoute={setRoute} />
          ) : (
            sideNav
          )
        ) : /^\/workspace\.*/.test(window.location.pathname) ? (
          sideNav
        ) : window.location.pathname === "/integration" ||
          window.location.pathname === "/integration/" ? (
          sideNav
        ) : window.location.pathname === "/settings" ? (
          sideNav
        ) : window.location.pathname === "/trial_expired" ||
          window.location.pathname === "/trial_expired/" ||
          window.location.pathname === "/change_plan" ? (
          environment().isAI ? (
            <DisabledCbbSideNav />
          ) : (
            <DisabledOmniSideNav setRoute={setRoute} />
          )
        ) : window.location.pathname === "/plan_expired" ||
          window.location.pathname === "/plan_expired/" ? (
          environment().isAI ? (
            <DisabledCbbSideNav />
          ) : (
            <DisabledOmniSideNav setRoute={setRoute} />
          )
        ) : null}
      </div>
      <Outlet />
      {showBooleanModal && (
        <BooleanModal
          onConfirm={() => handleRoute(true)}
          onCancel={() => handleRoute(false)}
        />
      )}
      {showModal && (
        <ShowModal
          modalType={modalType}
          setModal={handleSetModal}
          modalTitle={modalTitle}
          message={modalMessage}
          modalButtonText={modalButtonText}
          showModalButton={showModalButton}
          calledFromAgent={calledFromAgent}
        />
      )}
      {showToastPopUp && (
        <ToastPopUp
          setToast={handleSetToast}
          toastType={toastType}
          toastMessage={toastMessage}
          duration={toastDuration}
        />
      )}
      {trainingPending && isLoggedIn && (
        <ToastPopUp
          setToast={handleSetToast}
          toastType={"progress"}
          progressStatus={trainingProgress}
          text={
            "This may take a few seconds to minutes depending on the number of links"
          }
          trainBot={true}
        />
      )}
    </div>
  );
}
